import React, { useState, useEffect, Fragment, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import api from 'api';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import { Helmet } from "react-helmet";
import { NotificationManager } from 'react-notifications';

import Button from '@mui/material/Button';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

import Box from "@mui/material/Box";

const PurchaseEntry = (props) => {
    const location = useLocation();

    const itemCodeRef = useRef(null);
    const qtyRef = useRef(null);
    const partyRef = useRef(null);
    const warehouseRef = useRef(null);
    const invoiceNoRef = useRef(null);
    const invoiceDateRef = useRef(null);

    const [itemsList, setItemsList] = useState([]);
    const [partiesList, setPartiesList] = useState([]);
    const [warehousesList, setWarehousesList] = useState([]);
    const [states, setStates] = useState([]);
    const [purchaseSettings, setPurchaseSettings] = useState([]);

    const [myState, setMyState] = useState('');


    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const purchaseItemsListInitialValue = {
        id: 0,
        productID: 0,
        itemCode: '',
        itemName: '',
        qty: 0,
        ndp: 0,
        discPer: 0,
        discAmt: 0,
        rate: 0,
        taxable: 0,
        cgstPer: 0,
        cgstAmt: 0,
        sgstPer: 0,
        sgstAmt: 0,
        igstPer: 0,
        igstAmt: 0,
        cessPer: 0,
        cessAmt: 0,
        amount: 0,
        remarks: '',
    };
    const [purchaseItemsList, setPurchaseItemsList] = useState(purchaseItemsListInitialValue);
    const purchaseEntryInitialValue = {
        id: 0,
        partyID: 0,
        warehouseID: 0,
        invoiceNo: '',
        invoiceDate: moment(new Date().toLocaleString()).format("YYYY-MM-DD"),
        placeOfSupply: myState,
        itemList: [],
        billID: location.state.billID,
        netValue: 0,
        totalQty: 0
    };
    const [purchaseEntry, setPurchaseEntry] = useState(purchaseEntryInitialValue);

    const onChangePurchaseEntry = (key, value) => {
        var arr = { ...purchaseEntry };
        arr[key] = value;
        setPurchaseEntry(arr);
    }

    const onChangePurchaseItemDetails = (key, value) => {
        var arr = { ...purchaseItemsList };
        arr[key] = value;
        setPurchaseItemsList(prevState => arr);
    }

    useEffect(() => {
        setPurchaseEntry({ ...purchaseEntry, placeOfSupply: myState });
    }, [myState])

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (itemsList.length == 0) {
                console.log("itemlis loaded !");
                await api.get('/api/purchase/get-item-list')
                    .then((response) => {
                        setItemsList(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            if (partiesList.length == 0) {
                console.log("party loaded !");
                await api.get('/api/purchase/get-party-list')
                    .then((response) => {
                        setPartiesList(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            if (warehousesList.length == 0) {
                console.log("warehousesList loaded !");
                await api.get('/api/purchase/get-warehouse-list')
                    .then((response) => {
                        setWarehousesList(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            if (purchaseSettings.length == 0) {
                console.log("purchase settings loaded !");
                await api.get('/api/purchase/get-purchase-settings')
                    .then((response) => {
                        setPurchaseSettings(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            if (myState == '') {
                console.log("mystate loaded !");
                await api.get('/api/master/state/get-my-state')
                    .then((response) => {
                        setMyState(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            if (states.length == 0) {
                console.log("states loaded !");
                await api.get('/api/master/state?PageNumber=1&PageSize=100&status=Active')
                    .then((response) => {
                        setStates(response.data.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            await loadBillDetails();

            setLoading(false);
        })();
    }, []);

    const loadBillDetails = async () => {
        if (purchaseEntry.billID > 0 && loaded == false) {
            setLoaded(true);
            await api.get('api/purchase/get-purchase-entry?invoiceID=' + purchaseEntry.billID)
                .then((response) => {
                    console.log("Data found !");
                    console.log(response.data);
                    var res = response.data;
                    var arr = { ...purchaseEntry };

                    res.items.map(inv => {
                        let temp = { ...purchaseItemsList };
                        temp.id = inv.id;
                        temp.productID = inv.productId;
                        temp.itemCode = inv.itemCode;
                        temp.itemName = inv.itemName;
                        temp.qty = inv.qty;
                        temp.ndp = inv.ndp;
                        temp.discPer = inv.discPer;
                        temp.discAmt = inv.discAmt;
                        temp.rate = inv.rate;
                        temp.taxable = inv.taxable;
                        temp.cgstPer = inv.cgstPer;
                        temp.cgstAmt = inv.cgstAmt;
                        temp.sgstPer = inv.sgstPer;
                        temp.sgstAmt = inv.sgstAmt;
                        temp.igstPer = inv.igstPer;
                        temp.igstAmt = inv.igstAmt;
                        temp.cessPer = inv.cessPer;
                        temp.cessAmt = inv.cessAmt;
                        temp.amount = inv.amount;
                        temp.remarks = inv.remarks;
                        arr.itemList.push(temp);
                    });
                    arr.partyID = res.partyId;
                    arr.placeOfSupply = res.placeOfSupply;
                    arr.warehouseID = res.warehouseid;
                    arr.invoiceNo = res.invoiceNo;
                    arr.invoiceDate = res.invoiceDate;
                    arr.netValue = res.netBillValue;
                    arr.totalQty = res.totalQty;
                    arr.id = res.id;
                    setPurchaseEntry(arr);
                })
                .catch(error => {
                    NotificationManager.error('Error in loading bill details :' + JSON.stringify(error.responseText));
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoaded(true);
                    setLoading(false);
                });
        }
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: option => (option.itemName + option.itemCode)
    });

    const savePurchaseEntry = async () => {
        if (purchaseEntry.partyID == 0) {
            NotificationManager.error("Select party to continue..");
            partyRef.current.focus();
            return;
        }
        if (purchaseEntry.warehouseID == 0) {
            NotificationManager.error("Select warehouse to continue..");
            warehouseRef.current.focus();
            return;
        }
        if (purchaseEntry.invoiceNo.length == 0) {
            NotificationManager.error("Enter invoice no ..");
            invoiceNoRef.current.focus();
            return;
        }
        if (purchaseEntry.invoiceDate.length == 0) {
            NotificationManager.error("Enter invoice date ..");
            invoiceDateRef.current.focus();
            return;
        }
        if (purchaseEntry.itemList.length == 0) {
            NotificationManager.error("Select item to continue ..");
            itemCodeRef.current.focus();
            return;
        }
        setLoading(true);
        await api.post('api/purchase/save-purchase-entry', JSON.stringify(purchaseEntry))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Purchase Saved Successfully.");
                    setPurchaseEntry(purchaseEntryInitialValue);
                }
                else {
                    NotificationManager.error(JSON.stringify(response.data));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    };

    const addNewItem = () => {
        if (purchaseItemsList.productID == 0) {
            NotificationManager.error("select item to continue.");
            itemCodeRef.current.focus();
            return;
        }
        if (purchaseItemsList.qty == 0) {
            NotificationManager.error("Enter qty to continue.");
            qtyRef.current.focus();
            return;
        }
        console.log(purchaseItemsList);
        var arr = { ...purchaseEntry };
        arr.itemList.push(purchaseItemsList);
        setPurchaseEntry(arr);
        calculateAmount();
    }

    const calculateAmount = () => {
        let interState = true;
        if (purchaseEntry.placeOfSupply != myState) {
            interState = false;
        }

        var arr = { ...purchaseEntry };
        let taxable = 0;
        let cgstAmt = 0;
        let sgstAmt = 0;
        let igstAmt = 0;
        let cessAmt = 0;
        let totalQty = 0;
        let grossValue = 0;
        arr.itemList.map((item) => {
            if (purchaseSettings.includingTax == true) {
                item['rate'] = (item['ndp'] * 100) / (100 + item['cgstPer'] + item['sgstPer']);
            }
            else {
                item['rate'] = item['ndp'];
            }
            item['rate'] = parseFloat(item['rate'] - (item['rate'] * item['discPer'] / 100)).toFixed(2);
            item['discAmt'] = parseFloat(item['rate'] * (item['discPer'] / 100) * item['qty']).toFixed(2);
            item['taxable'] = parseFloat(item['rate'] * item['qty']).toFixed(2);

            if (interState == true) {
                item['cgstAmt'] = parseFloat(item['taxable'] * item['cgstPer'] / 100).toFixed(2);
                item['sgstAmt'] = parseFloat(item['taxable'] * item['sgstPer'] / 100).toFixed(2);
                item['igstAmt'] = 0;
            }
            else {
                item['cgstAmt'] = 0;
                item['sgstAmt'] = 0;
                item['igstAmt'] = parseFloat(item['taxable'] * item['igstPer'] / 100).toFixed(2);
            }
            item['cessAmt'] = parseFloat(item['taxable'] * ((item['cessPer']) ?? 0) / 100).toFixed(2);
            item['amount'] = (Number(item['taxable']) + Number(item['cgstAmt']) + Number(item['sgstAmt']) + Number(item['igstAmt']) + Number(item['cessAmt'])).toFixed(2);

            totalQty += Number(item['qty']);
            taxable += Number(item['taxable']);
            cgstAmt += Number(item['cgstAmt']);
            sgstAmt += Number(item['sgstAmt']);
            igstAmt += Number(item['igstAmt']);
            cessAmt += Number(item['cessAmt']);

        });
        grossValue = (Number(taxable) + Number(cgstAmt) + Number(sgstAmt) + Number(igstAmt) + Number(cessAmt)).toFixed(2);
        arr.netValue = Math.round(grossValue);
        arr.totalQty = totalQty;
        setPurchaseEntry(arr);


        setPurchaseItemsList(purchaseItemsListInitialValue);
        itemCodeRef.current.focus();
    }

    const deleteItem = async (id, index) => {
        if (id <= 0) {
            var arrs = { ...purchaseEntry };
            arrs.itemList.splice(index, 1);
            setPurchaseEntry(arrs);
            calculateAmount();
            return;
        }

        setLoading(true);
        await api.post('api/purchase/delete-inventory-by-id?id=' + id)
            .then((response) => {
                if (response.status === 200) {
                    var arrs = { ...purchaseEntry };
                    arrs.itemList.splice(index, 1);
                    setPurchaseEntry(arrs);
                    calculateAmount();
                }
                else {
                    NotificationManager.error(JSON.stringify(response.data));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    }

    // const editItem = (index) => {
    //     var arrs = { ...purchaseEntry };
    //     let tempItem = arrs.itemList[index];

    //     console.log(tempItem);
    //     let temp = { ...purchaseItemsList };
    //     temp.qty = tempItem['qty'];
    //     temp.ndp = tempItem['ndp'];
    //     temp.discPer = tempItem['discPer'];
    //     temp.remarks = tempItem['remarks'];
    //     setPurchaseItemsList(temp);
    //     console.log(purchaseItemsList);
    //     console.log(temp);

    //     arrs.itemList.splice(index, 1);
    //     //setPurchaseEntry(arrs);
    //     calculateAmount();
    // }

    return (
        <div className="card-wrapper">
            <PageTitleBar title={'Purchase Entry'} match={props.match} />
            <Helmet>
                <title>Purchase Entry</title>
                <meta name="description" content="Purchase Entry" />
            </Helmet>
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <Button
                            variant="contained"
                            className="text-white btn-danger"
                            disabled={loading}
                            onClick={savePurchaseEntry}>
                            {loading ? 'Please Wait ... ' : 'Save & Print'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{purchaseEntry.netValue})[Qty(s) : {purchaseEntry.totalQty}]</small></Button>
                    </div>
                </div>

                {loading &&
                    <RctSectionLoader />
                }
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-4">
                        <FormControl className="form-control">
                            <InputLabel>Party</InputLabel>
                            <Select
                                inputRef={partyRef}
                                value={purchaseEntry.partyID}
                                onChange={(e) => onChangePurchaseEntry('partyID', e.target.value)}
                                style={{ width: '100%' }}
                            >
                                <MenuItem selected key={0} value={0}>----Select Party----</MenuItem>
                                {partiesList && partiesList.map((party) => <MenuItem key={party.id} value={party.id}>{party.partyName}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <FormControl className="form-control">
                            <InputLabel id="warehouseList">Warehouse</InputLabel>
                            <Select
                                inputRef={warehouseRef}
                                value={purchaseEntry.warehouseID}
                                onChange={(e) => onChangePurchaseEntry('warehouseID', e.target.value)}
                                style={{ width: '100%' }}
                            >
                                <MenuItem selected key={0} value={0}>----Select Warehouse----</MenuItem>
                                {warehousesList && warehousesList.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <FormControl className="form-control">
                            <InputLabel>State</InputLabel>
                            <Select
                                value={purchaseEntry.placeOfSupply}
                                onChange={(e) => onChangePurchaseEntry('placeOfSupply', e.target.value)}
                                style={{ width: '100%' }}
                            >
                                {states && states.map((state) => <MenuItem key={state.stateName} value={state.stateName}>{state.stateName}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>

                </div>
                <div className='row'>
                    <div className="col-md-3">
                        <FormControl className="form-control">
                            <TextField
                                inputRef={invoiceNoRef}
                                type="text"
                                label="Invoice No."
                                value={purchaseEntry.invoiceNo}
                                onChange={(e) => onChangePurchaseEntry('invoiceNo', e.target.value)}
                            >
                            </TextField>
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl className="form-control">
                            <TextField
                                inputRef={invoiceDateRef}
                                type="date"
                                label="Invoice Date"
                                value={moment(purchaseEntry.invoiceDate).format("YYYY-MM-DD")}
                                onChange={(e) => onChangePurchaseEntry('invoiceDate', e.target.value)}
                            >
                            </TextField>
                        </FormControl>
                    </div>
                </div>
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-4">
                        <Autocomplete
                            value={purchaseItemsList}
                            options={itemsList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) => option.productID > 0 ? option.itemCode + '-' + option.itemName : ''}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                    console.log(newValue);
                                    var arr = { ...purchaseItemsList };
                                    arr['productID'] = newValue.id;
                                    arr['itemCode'] = newValue.itemCode;
                                    arr['itemName'] = newValue.itemName;
                                    arr['ndp'] = newValue.ndp ?? 0;
                                    arr['cgstPer'] = newValue.cgstPer ?? 0;
                                    arr['sgstPer'] = newValue.sgstPer ?? 0;
                                    arr['igstPer'] = newValue.igstPer ?? 0;
                                    arr['cessPer'] = newValue.cessPer ?? 0;
                                    setPurchaseItemsList(arr);
                                    qtyRef.current.focus();
                                }
                            }}
                            autoHighlight
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.id}>
                                    {option.itemCode + " - " + option.itemName}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputRef={itemCodeRef}
                                    label="Choose an item"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-2">
                        <TextField
                            inputRef={qtyRef}
                            label="Qty"
                            placeholder="Qty" type="number"
                            onChange={(e) => onChangePurchaseItemDetails('qty', e.target.value)}
                            value={purchaseItemsList.qty}
                            variant="outlined"
                        ></TextField>
                    </div>
                    <div className="col-md-2">
                        <TextField
                            label="NDP"
                            placeholder="NDP" type="number"
                            onChange={(e) => onChangePurchaseItemDetails('ndp', e.target.value)}
                            value={purchaseItemsList.ndp}
                            variant="outlined"
                        ></TextField>
                    </div>
                    <div className="col-md-2">
                        <TextField
                            label="Disc (%)"
                            placeholder="Disc (%)" type="number"
                            onChange={(e) => onChangePurchaseItemDetails('discPer', e.target.value)}
                            value={purchaseItemsList.discPer}
                            variant="outlined"
                        ></TextField>
                    </div>
                    <div className="col-md-2">
                        <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                    </div>
                </div>
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="table-responsive">
                    <Table>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Item</TableCell>
                                <TableCell>Discount</TableCell>
                                <TableCell>Taxable = Qty*Rate</TableCell>
                                <TableCell>GST</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Fragment>
                                {purchaseEntry && purchaseEntry.itemList.map((menuItem, key) => (
                                    <TableRow hover key={key}>
                                        <TableCell>{menuItem["itemCode"]} <br /> {menuItem["itemName"]} (<small>NDP : {'\u20B9'}{menuItem["ndp"]}</small>)</TableCell>
                                        <TableCell>{menuItem["discPer"]} %<br /> (<small>Disc Amt : {'\u20B9'}{menuItem["discAmt"]}</small>)</TableCell>
                                        <TableCell>{menuItem["taxable"]} <br /> <small><b>({menuItem["qty"]} * {menuItem["rate"]})</b></small></TableCell>
                                        <TableCell>
                                            <small>CGST ({menuItem["cgstPer"]}%)</small> = {menuItem["cgstAmt"]} <br />
                                            <small>SGST ({menuItem["sgstPer"]}%)</small> = {menuItem["sgstAmt"]} <br />
                                            <small>IGST ({menuItem["igstPer"]}%)</small> = {menuItem["igstAmt"]} <br />
                                            <small>Cess ({menuItem["cessPer"]}%)</small> = {menuItem["cessAmt"]} <br />
                                        </TableCell>
                                        <TableCell>{menuItem["amount"]}</TableCell>
                                        <TableCell>
                                            <>
                                                <IconButton color="primary" onClick={() => deleteItem(menuItem["id"], key)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                {/* <IconButton color="primary" onClick={() => editItem(key)}>
                                                        <EditIcon />
                                                    </IconButton> */}
                                            </>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>
                        </TableBody>
                    </Table>
                </div>
            </RctCollapsibleCard>
        </div>
    );
}

export default PurchaseEntry;