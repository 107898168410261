/**
* Report Page
*/
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
// page title bar
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import api from 'api';

// rct collapsible card
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { NotificationManager } from 'react-notifications';

const ChangeBranch = (props) => {
    const [branchID, setBranchID] = useState(0);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        (async () => {
            await api.get('api/master/branch/get-branch-by-user')
                .then((response) => {
                    setBranches(response.data);
                })
                .catch(error => {
                    NotificationManager.error(JSON.stringify(error));
                });

        })();
        setLoading(false);
        getDefaultBranch();
    }, []);

    const getDefaultBranch = async () => {
        setLoading(true);
        await api.get('api/master/login/get-default-branch-user')
            .then((response) => {
                console.log(response.data);
                setBranchID(response.data);
            })
            .catch(error => {
                console.log(JSON.stringify(error));
            });
        setLoading(false);
    }

    const saveBranch = async () => {
        setLoading(true);
        await api.post('api/master/login/set-default-branch-user?branchID=' + branchID)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    NotificationManager.success("SUCCESS");
                    getDefaultBranch();
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            });
        setLoading(false);
    }

    return (
        <div className="formelements-wrapper">
            <PageTitleBar title={'Change Branch'} />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <Form inline>
                    <FormGroup>
                        <Label for="branch">Select Branch</Label>
                        <select id="branch" className="form-control" value={branchID} onChange={(e) => setBranchID(e.target.value)}>
                            {branches && branches.map((branch) => <option key={branch.id} value={branch.id}>{branch.branchName}</option>)}
                        </select>
                    </FormGroup>
                    <Button variant="contained" className="text-white btn-danger" onClick={saveBranch}>Update Branch</Button>
                </Form>
            </RctCollapsibleCard>
        </div >
    )
}

export default ChangeBranch;