/**
 * User Management Page
 */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
// api
import api from 'api';

// delete confirmation dialog
import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';

import AddIcon from '@mui/icons-material/Add';
// page title bar   
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import moment from 'moment';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
export default function CategoryMaster(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openViewItemDialog, setOpenViewItemDialog] = useState(false);
    const deleteConfirmationDialog = useRef(null);
    const [searchBy, setSearchBy] = useState({
        status: "Active",
        searchText: ''
    });

    useEffect(() => {
        if (items.length === 0) {
            loadItems();
        }
    }, [items]);

    const loadItems = async () => {
        (async () => {
            await api.get('api/master/coupon?status=' + searchBy.status)
                .then((response) => {
                    console.log(response.data.data);
                    setItems(response.data.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    const deleteItemPermanently = async () => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        await api.delete('api/master/coupon?id=' + selectedItem.id)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Deleted!');
                    loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response.message.data));
                    console.log(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron Salon | Coupon Master</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Coupon Master'}
                match={props.match}
            />

            <RctCollapsibleCard>
                {loading &&
                    <RctSectionLoader />
                }
                <div className="row">
                    <div className='col-md-3'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={searchBy.status}
                                onChange={(e) => setSearchBy({ ...searchBy, status: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                <MenuItem selected key={0} value="Active">Active</MenuItem>
                                <MenuItem key={1} value="Cancelled">Cancelled</MenuItem>
                                <MenuItem key={1} value="Expired">Expired</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-3'>
                        <a href="#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10">Search</a>
                    </div>
                    <div className='col-md-3'>
                    </div>
                    <div className='col-md-3 text-right'>
                        <Link to='/app/coupon-master' state={{ id: 0, items: items }} className="btn btn-primary d-flex"><AddIcon />Add New Coupon</Link>
                    </div>
                </div>
            </RctCollapsibleCard >
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    {items && <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Coupon Code</b></TableCell>
                                <TableCell><b>Discount Type</b></TableCell>
                                <TableCell><b>Discount</b></TableCell>
                                <TableCell><b>Min Bill Value</b></TableCell>
                                <TableCell><b>Valid Till</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                                <TableCell><b>Actions</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items && items.map((item, key) => (

                                <TableRow key={key}>
                                    <TableCell>{item.couponCode}</TableCell>
                                    <TableCell>{item.discountType}</TableCell>
                                    <TableCell>{item.discount}</TableCell>
                                    <TableCell>{item.minBillValue}</TableCell>
                                    <TableCell>{moment(item.validTill).format('DD-MMM-YYYY')}</TableCell>
                                    <TableCell>
                                        <span className={item.status == "Expired" || item.status == "Cancelled" ? "badge badge-danger" : "badge badge-primary"}>{item.status}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <Link to='/app/coupon-master' state={{ id: item.id }}>
                                            <IconButton
                                                aria-label="Edit"
                                                size='small'
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Link>

                                        <IconButton
                                            onClick={() => onDelete(item)}
                                            aria-label="Delete"
                                            size='small'
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                            )}
                        </TableBody>
                    </Table>}
                </div>
            </RctCollapsibleCard>


            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete permanently."
                onConfirm={() => deleteItemPermanently()}
            />
            <Dialog
                onClose={() => setOpenViewItemDialog(false)}
                open={openViewItemDialog}
            >

            </Dialog>
        </div >
    );
}
