import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import api from 'api';
import { NotificationManager } from 'react-notifications';

export default function CancelBill(props) {
    const [billDatas, setBillDatas] = useState([]);
    const [found, setFound] = useState(false);
    const [orderNo, setOrderNo] = useState('');
    const [startSubmit, setStartSubmit] = useState(false);


    useEffect(() => {
    }, []);

    const getBillDetails = async () => {
        await api.get('api/rest/kot-bill/get-billdetails-by-billno?billNo=' + orderNo)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setBillDatas(response.data);
                        setFound(true);
                        setStartSubmit(false);
                        NotificationManager.success("Order Found");
                    }
                    else {
                        setBillDatas([]);
                        setFound(false);
                        setStartSubmit(false);
                        NotificationManager.error("Order Not Found");

                    }
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                    setStartSubmit(false);
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
                setStartSubmit(false);
            });

    }

    const cancelBill = async () => {
        //console.log(billDatas[0].id);
        if (billDatas.length === 0) {
            NotificationManager.error("Order details not found");
            return;
        }
        setStartSubmit(true);
        await api.post('api/rest/kot-bill/cancel-bill?billID=' + billDatas[0].id)
            .then((response) => {
                if (response.status === 200) {
                    setBillDatas([]);
                    setFound(false);
                    setOrderNo('');
                    setStartSubmit(false);
                    NotificationManager.success("Bill Cancelled Successfully.");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            }).finally(() => {
                setStartSubmit(false);
            });
    }

    const printBill = async () => {
        if (billDatas.length === 0) {
            NotificationManager.error("Order details not found");
            return;
        }
        setStartSubmit(true);
        console.log('api/rest/kot-bill/print-bill?billID=' + billDatas[0].id);
        await api.get('api/rest/kot-bill/print-bill?billID=' + billDatas[0].id)
            .then((response) => {
                if (response.status === 200) {
                    window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                } else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            }).finally(() => {
                setStartSubmit(false);
            });
    }

    return (
        <div className="formelements-wrapper">
            <PageTitleBar title={'Cancel Bill'} match={props.match} />

            <RctCollapsibleCard>
                <Form inline>
                    <FormGroup className="mr-10 mb-10">
                        <Label for="Email-7">Order No.</Label>
                        <Input type="text" name="email" id="Email-7" placeholder="Enter order no." value={orderNo} onChange={(e) => setOrderNo(e.target.value)} />
                    </FormGroup>
                    <Button className="mb-10" color="primary" onClick={getBillDetails}>Get Bill Detail</Button>
                    {'\u00A0'}{'\u00A0'}
                    <Button className="mb-10" color="danger" disabled={!found || startSubmit} onClick={cancelBill}>{startSubmit ? 'Please wait...' : 'Cancel Order'}</Button>
                    {'\u00A0'}{'\u00A0'}
                    <Button className="mb-10" disabled={!found || startSubmit} onClick={printBill}>{startSubmit ? 'Please wait...' : 'Print Duplicate Bill'}</Button>
                </Form>
            </RctCollapsibleCard>

            <div className="row">
                <div className="col-sm-6 col-md-6 col-xl-6 col-12">
                    <RctCollapsibleCard heading="Bill Details">
                        <Form>
                            <FormGroup>
                                <Label for="OrderNo">Order No.</Label>
                                <Input type="text" name="OrderNo" placeholder="Order No." readOnly value={billDatas.length > 0 ? billDatas[0].billNo : ''} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="OrderDte">Order Date</Label>
                                <Input type="text" name="OrderDte" placeholder="Order Date" readOnly value={billDatas.length > 0 ? billDatas[0].billDate : ''} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="BillValue">Order Date</Label>
                                <Input type="text" name="BillValue" placeholder="Bill Value" readOnly value={billDatas.length > 0 ? billDatas[0].billValue : ''} />
                            </FormGroup>
                        </Form>
                    </RctCollapsibleCard>
                </div>
                <div className="col-sm-6 col-md-6 col-xl-6 col-12">
                    <RctCollapsibleCard heading="Item Details">
                        <div className="table-responsive">
                            <Table>
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell>Item</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Rate</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {billDatas && billDatas.map((billData, key) => (
                                            <TableRow hover key={key}>
                                                <TableCell>{billData["itemName"]}</TableCell>
                                                <TableCell>{billData["qyantity"]}</TableCell>
                                                <TableCell>{billData["rate"]}</TableCell>
                                                <TableCell>{billData["amount"]}</TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                </TableBody>
                            </Table>
                        </div>
                    </RctCollapsibleCard>
                </div>
            </div>
        </div>
    );
}
