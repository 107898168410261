/**
 * User Management Page
 */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { NotificationManager } from 'react-notifications';

// api
import api from 'api';

// delete confirmation dialog
import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';

// add new user form
import AddNewUserForm from './AddNewItemForm';

// page title bar
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';


// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import moment from 'moment';
import '@trendmicro/react-paginations/dist/react-paginations.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';


const BrandMaster = (props) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openViewItemDialog, setOpenViewItemDialog] = useState(false);
    const deleteConfirmationDialog = useRef(null);



    useEffect(() => {
        if (items.length === 0) {
            loadItems();
        }
    }, [items]);



    const loadItems = async () => {
        (async () => {
            await api.get('/api/master/product-brand')


                .then((response) => {
                    setItems(response.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                })

        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    }

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    const deleteItemPermanently = async () => {
        setLoading(true);
        await api.delete('api/master/product-brand?id=' + selectedItem.brdGrpNo)
            .then((response) => {
                if (response.status === 200) {
                    let itemsLocal = [...items];
                    let indexOfDeleteItem = itemsLocal.indexOf(selectedItem);
                    itemsLocal.splice(indexOfDeleteItem, 1);
                    deleteConfirmationDialog.current.close();
                    setTimeout(() => {
                        setSelectedItem(null);
                        setItems(itemsLocal);
                        NotificationManager.success('Deleted!');
                    }, 2000);
                }
                else {
                    NotificationManager.error(JSON.stringify(response.message));
                    console.log(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.message));
                console.log(JSON.stringify(error));
            })
            .finally(() => {
                setLoading(false);
            });
    }






    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron POS | Brand Management</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Brand Master'}
                match={props.match}
            />

            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"></i></a>
                        </div>
                        <div>
                            <a href="#" onClick={(e) => opnAddNewItemModal(e)} color="primary" className="caret btn-sm mr-10">Add New brand <i className="zmdi zmdi-plus"></i></a>
                        </div>
                    </div>

                    <RctCollapsibleCard fullBlock>
                        {items && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Brand</b></TableCell>
                                    <TableCell><b>Status</b></TableCell>
                                    <TableCell><b>Actions</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && items.map((item, key) => (

                                    <TableRow key={key}>
                                        <TableCell>
                                            <div className="media">
                                                <div className="media-left mr-15">
                                                    {item.brandGrp}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => onEditItem(item)}
                                                aria-label="Edit"
                                                size='small'
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => onDelete(item)}
                                                aria-label="Delete"
                                                size='small'
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>}

                    </RctCollapsibleCard>
                </div>

                {loading &&
                    <RctSectionLoader />
                }
            </RctCollapsibleCard>

            {editItem === null ?
                <AddNewUserForm editItem={editItem} setEditItem={setEditItem} addNewItemModal={addNewItemModal} setAddNewItemModal={setAddNewItemModal} loadItems={loadItems} />
                :
                <AddNewUserForm editItem={editItem} setEditItem={setEditItem} addNewItemModal={addNewItemModal} setAddNewItemModal={setAddNewItemModal} loadItems={loadItems} />
            }

            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete permanently."
                onConfirm={() => deleteItemPermanently()}
            />
            <Dialog
                onClose={() => setOpenViewItemDialog(false)}
                open={openViewItemDialog}
            >
                <DialogContent>
                    {selectedItem !== null &&
                        <div>
                            <div className="clearfix d-flex">
                                <div className="media pull-left">
                                    <div className="media-body">
                                        <p>Category: <span className="fw-bold">{selectedItem.productGrp}</span></p>
                                        <p>Status   : <span className={selectedItem.status == "Active" ? "badge badge-primary" : "badge badge-danger"}>{selectedItem.status}</span></p>
                                        <p>Creation : {moment(selectedItem.creationTime).format("DD-MMM-yyyy")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default BrandMaster;