/**
 * User Management Page
 */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { NotificationManager } from 'react-notifications';
import { Form, FormGroup, Label } from 'reactstrap';
// api
import api from 'api';

// delete confirmation dialog
import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';

// add new user form
import AddNewUserForm from './AddNewItemForm';
//Import Items
import ImportItems from './ImportItems';

// page title bar
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';


// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

import moment from 'moment';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { Input } from 'reactstrap';
const ItemMaster = (props) => {
    const [loading, setLoading] = useState(false);
    const [itemLoadedFirstTime, setItemLoadedFirstTime] = useState(false);
    const [items, setItems] = useState([]);
    const [addNewItemModal, setAddNewItemModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openViewItemDialog, setOpenViewItemDialog] = useState(false);
    const deleteConfirmationDialog = useRef(null);
    const [importItemModal, setImportItemModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [status, setStatus] = useState('Active');

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    useEffect(() => {
        if (itemLoadedFirstTime === false) {
            loadItems();
        }
    }, [items]);

    useEffect(() => {
        (async () => {
            loadItems(page);
        })();
    }, [page, rowsPerPage]);

    const loadItems = async () => {
        (async () => {
            setLoading(true);
            await api.get('api/master/product?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                params: {
                    searchText: searchText,
                    status: status
                }
            })
                .then((response) => {
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                    setItemLoadedFirstTime(true);
                })
                .catch(error => {
                }).finally(() => {
                    setLoading(false);
                });
        })();
    }

    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        loadItems();
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const opnAddNewItemModal = (e) => {
        e.preventDefault();
        setAddNewItemModal(true);
    }

    const opnImportItemModal = (e) => {
        e.preventDefault();
        setImportItemModal(true);
    }

    const onEditItem = (item) => {
        setAddNewItemModal(true);
        setEditItem(items[items.indexOf(item)]);
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    const deleteItemPermanently = async () => {
        setLoading(true);
        await api.delete('api/master/product?id=' + selectedItem.id)
            .then((response) => {
                if (response.status === 200) {
                    let itemsLocal = [...items];
                    let indexOfDeleteItem = itemsLocal.indexOf(selectedItem);
                    itemsLocal.splice(indexOfDeleteItem, 1);
                    deleteConfirmationDialog.current.close();
                    setTimeout(() => {
                        setLoading(false);
                        setSelectedItem(null);
                        setItems(itemsLocal);
                        NotificationManager.success('Item Deleted!');
                    }, 2000);
                }
                else {
                    NotificationManager.error(JSON.stringify(response.message));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));

            }).finally(() => {
                setLoading(false);
            });


    }


    const handleChangePage = async (newPage) => {
        //setPage(page + 1); 
        console.log("page........");
        console.log("New Page : " + newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        console.log("Rows per page changed !");
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function TablePaginationActions() {

        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }



    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron POS | Item Management</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Item Master'}
                match={props.match}
            />
            <RctCollapsibleCard>
                {loading &&
                    <RctSectionLoader />
                }
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10">
                        <div>
                        </div>
                        <div>
                            <Link className="caret btn-sm" to={{ pathname: "/app/price-management" }}>Price Management</Link>|
                            <a href="#" onClick={(e) => opnImportItemModal(e)} className="caret btn-sm mr-10">Import Item(s)</a>
                        </div>
                    </div>

                    <Form className='row' sx={{ mt: 5 }}>
                        <FormGroup className='col-lg-3'>
                            <TextField label="Search Text"
                                fullWidth
                                size="small"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className='col-lg-3'>
                            <FormControl sx={{ width: "100%", mr: 1 }} size="small">
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="InActive">InActive</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                        <FormGroup className='col-lg-1'>
                            <button className="btn btn-primary ml-2"><i className="ti-reload" onClick={(e) => onReload(e)}></i></button>
                        </FormGroup>
                        <FormGroup className='col-lg-3'>
                        </FormGroup>
                        <FormGroup className='col-lg-2'>
                            <a href="#" onClick={(e) => opnAddNewItemModal(e)} color="primary" className="btn btn-primary d-flex"><AddIcon />Add New Item</a>
                        </FormGroup>
                    </Form>
                    {items && <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Type</TableCell>
                                <TableCell>Department</TableCell>
                                <TableCell>Brand</TableCell>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Hsn Code</TableCell>
                                <TableCell>NDP</TableCell>
                                <TableCell>MRP</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items && items.map((item, key) => (
                                (item.brand != null ?
                                    <TableRow key={key}>
                                        <TableCell>
                                            <div className="media">
                                                <div className="media-left mr-15">
                                                    {item.itemType}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>{item.department}</TableCell>
                                        <TableCell>{item.brand}</TableCell>
                                        <TableCell>
                                            <b>{item.itemCode}</b>
                                        </TableCell>
                                        <TableCell><div className="media-left mr-15">
                                            {item.itemName}
                                        </div>
                                        </TableCell>
                                        <TableCell>{item.hsncode}</TableCell>
                                        <TableCell>{item.ndp}</TableCell>
                                        <TableCell>{item.mrp}</TableCell>
                                        <TableCell>
                                            <span className={item.status == "InActive" ? "badge badge-danger" : "badge badge-primary"}>{item.status}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => onEditItem(item)}
                                                color='primary'
                                                aria-label="Edit"
                                                size='small'
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => onDelete(item)}
                                                color='error'
                                                aria-label="Delete"
                                                size='small'
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow> : null)
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page + 1}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary"
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number"
                                        }
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>}
                </div>
            </RctCollapsibleCard >
            {editItem === null ?
                <AddNewUserForm editItem={editItem} setEditItem={setEditItem} addNewItemModal={addNewItemModal} setAddNewItemModal={setAddNewItemModal} loadItems={loadItems} />
                :
                <AddNewUserForm editItem={editItem} setEditItem={setEditItem} addNewItemModal={addNewItemModal} setAddNewItemModal={setAddNewItemModal} loadItems={loadItems} />
            }

            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete item permanently."
                onConfirm={() => deleteItemPermanently()}
            />


            <Dialog
                onClose={() => setOpenViewItemDialog(false)}
                open={openViewItemDialog}
            >
                <DialogContent>
                    {selectedItem !== null &&
                        <div>
                            <div className="clearfix d-flex">
                                <div className="media pull-left">
                                    {/* <img src={selectedUser.avatar} alt="user prof" className="rounded-circle mr-15" width="50" height="50" /> */}
                                    <div className="media-body">
                                        <p>Item Code: <span className="fw-bold">{selectedItem.itemCode}</span></p>
                                        <p>Item Name: <span className="fw-bold">{selectedItem.itemName}</span></p>
                                        <p>Price    : <span className="badge badge-warning">{selectedItem.mrp}</span></p>
                                        <p>Status   : <span className={selectedItem.status == "Active" ? "badge badge-primary" : "badge badge-danger"}>{selectedItem.status}</span></p>
                                        <p>Non-Veg  : {selectedItem.redTag}</p>
                                        <p>Creation : {moment(selectedItem.ctime).format("DD-MMM-yyyy")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>


            <ImportItems importItemModal={importItemModal} setImportItemModal={setImportItemModal} />

        </div>
    );

};
export default ItemMaster;