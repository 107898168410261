// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconSettings
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const configuration = {
    id: 'configuration',
    title: 'Configuration',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'configuration',
            title: 'Configuration',
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'itemmaster',
                    title: 'Item Master',
                    type: 'item',
                    url: 'app/item-master',
                    breadcrumbs: false
                },
                {
                    id: 'accountmaster',
                    title: 'Account Master',
                    type: 'item',
                    url: 'app/account-master-list',
                    breadcrumbs: false
                },
                {
                    id: 'serviceprovidermaster',
                    title: 'Service Provider Master',
                    type: 'item',
                    url: 'app/service-provider-master-list',
                    breadcrumbs: false
                },
                {
                    id: 'couponmaster',
                    title: 'Coupon Master',
                    type: 'item',
                    url: 'app/coupon-master-list',
                    breadcrumbs: false
                },
                {
                    id: 'mopmaster',
                    title: 'MOP Master',
                    type: 'item',
                    url: 'app/mop-master-list',
                    breadcrumbs: false
                },
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'item',
                    url: 'app/settings',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default configuration;
