import React, { useEffect, useState } from 'react'; 
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
// page title bar
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import api from 'api';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { TextField } from '@mui/material';

// rct collapsible card
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { NotificationManager } from 'react-notifications';
var moment = require('moment');

const StockTransferReport = (props) => { 
    const [orders, setOrders] = useState(null);
    const [ordersdetail, setOrdersDetail] = useState(null);

    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());
    const [searchText, setSearchText] = useState('');
    const [godownFromId, setGodownFromId] = useState(0);
    const [godownToId, setGodownToId] = useState(0);
    const [warehouses, setWarehouses] = useState(null);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modelOpen, setModelOpen] = useState(false); 
 
    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);

    useEffect(() => {
        (async () => {
            if (warehouses == null)
                await loadWareHouse();
        })();
    }, []);

    const loadWareHouse = async () => {
        await api.get('/api/stock-transfer/get-warehouse-list')
            .then((response) => {
                setWarehouses(response.data);
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            });
    }

    const loadData = async () => {
        setLoading(true);
        await api.get('api/rest/report/get-stock-transfer-register?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
            params: {
                fromDate: startDate,
                toDate: endDate,
                godownfromid: godownFromId,
                godowntoid: godownToId,
                searchText: searchText
            }
        })
            .then((response) => {
                console.log(response.data);
                setCount(response.data.totalRecords);
                setOrders(response.data.data);
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const exportReport = async () => { 
        setLoading(true);
        await api.get('api/rest/report/get-stock-transfer-register?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
            params: {
                fromDate: startDate,
                toDate: endDate,
                godownfromid: godownFromId,
                godowntoid: godownToId,
                searchText: searchText,
                export: true
            }
        })
            .then((msg) => {
                console.log(msg);
                var a = document.createElement("a");
                a.href = msg.data;
                a.download = "StockTransferRegister.xlsx";
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleChangePage = async (newPage) => {
        //setPage(page + 1); 
        console.log("page........");
        console.log("New Page : " + newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        console.log("Rows per page changed !");
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function TablePaginationActions() {

        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    const onModalOpenHandler = async (e, id) => {
        e.preventDefault();
         setLoading(true);
        await api.get('api/rest/report/get-item-from-stock-transfer?id=' + id)
            .then((response) => {
                setModelOpen(true)
                setOrdersDetail(response.data);
                console.log(response.data);
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Helmet>
                <title>BillTaxReport</title>
                <meta name="description" content="BillTax Reports" />
            </Helmet>
            <PageTitleBar title='Stock Transfer Report' match='app/billtaxreport' />
           
            <RctCollapsibleCard> 
                <div className="row">
                    <div className="col-md-2 mt-10">  
                            <TextField
                            type="date"
                            label="Start Date"
                            value={moment(startDate).format("YYYY-MM-DD")}
                            onChange={(e) => setStartDate(e.target.value)}
                            /> 
                    </div>
                    <div className="col-md-2 mt-10">  
                            <TextField
                            type="date"
                            label="End Date"
                            value={moment(endDate).format("YYYY-MM-DD")}
                            onChange={(e) => setEndDate(e.target.value)}
                            /> 
                    </div>
                    <div className="col-md-2 mt-10">  
                        <TextField
                            type="text"
                            label="searchText"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{width:'100%'}}
                            />  
                    </div>
                    <div className='col-md-2 mt-10'>
                            <Select  
                                        value={godownFromId}
                                        onChange={(e) => setGodownFromId(e.target.value)}
                                        style={{width:'100%'}} 
                                    >
                                        <MenuItem key={0} value={0} selected>---select warehouse (from)</MenuItem>
                                        {warehouses && warehouses.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                            </Select>  
                    </div>
                    <div className='col-md-2 mt-10'>
                        <Select  
                                        value={godownToId}
                                        onChange={(e) => setGodownToId(e.target.value)}
                                        style={{width:'100%'}}
                                    >
                                        <MenuItem key={0} value={0} selected>---select warehouse (to)</MenuItem>
                                        {warehouses && warehouses.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                            </Select>  
                    </div>
                    <div className='col-md-1 mt-10'> 
                        <Button color="primary" variant="contained" className="mr-10 text-white" onClick={loadData}>Search</Button>    
                    </div>
                    <div className='col-md-1 mt-10'> 
                        <Button color='success' variant="contained" className="mr-10 text-white" onClick={exportReport}>Export</Button>                  
                    </div>
                </div>
            </RctCollapsibleCard>

            <RctCollapsibleCard>
                <div className='table-responsive'>
                {loading &&
                    <RctSectionLoader />
                }
                {orders && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Doc No</TableCell>
                            <TableCell>Doc date</TableCell>
                            <TableCell>Godown (from)</TableCell>
                            <TableCell>Godown (to)</TableCell>
                            <TableCell align="right">MRP Value ({'\u20B9'})</TableCell>
                            <TableCell align="right">Purchase Value ({'\u20B9'})</TableCell>
                            <TableCell>Total Qty</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Creation Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders && orders.map((order, key) => (
                            (order.createdBy != null ?
                                <TableRow key={key}>
                                    <TableCell><button onClick={(e) => onModalOpenHandler(e, order.id)}>{order.docNo}</button></TableCell>
                                    <TableCell>{moment(order.docDate).format("DD-MMM-YYYY")}</TableCell>
                                    <TableCell>{order.godownFrom}</TableCell>
                                    <TableCell>{order.godownTo}</TableCell>
                                    <TableCell align='right'>{order.mrpValue}</TableCell>
                                    <TableCell align='right'>{order.purchaseValue}</TableCell>
                                    <TableCell align='right'>{order.totalQty}</TableCell>
                                    <TableCell>{order.createdBy}</TableCell>
                                    <TableCell>{moment(order.creationTime).format("DD-MMM-YYYY hh:mm a")}</TableCell>
                                </TableRow> : null)
                        ))}
                    </TableBody>
                    <TableFooter>
                        {orders.length > 1 && <TableRow>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell align='right'>Total :  </TableCell>
                            <TableCell align='right'>{orders[orders.length - 1].mrpValue}</TableCell>
                            <TableCell align='right'>{orders[orders.length - 1].purchaseValue}</TableCell>
                            <TableCell align='right'>{orders[orders.length - 1].totalQty}</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                        </TableRow>
                        }
                        <TableRow>
                            <TablePagination
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                labelRowsPerPage={<span>Rows:</span>}
                                labelDisplayedRows={({ page }) => {
                                    return `Page: ${page + 1}`;
                                }}
                                backIconButtonProps={{
                                    color: "secondary"
                                }}
                                nextIconButtonProps={{ color: "secondary" }}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "page number"
                                    }
                                }}
                                showFirstButton={true}
                                showLastButton={true}
                                ActionsComponent={TablePaginationActions}
                            //component={Box}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>}
                </div>
            </RctCollapsibleCard >

            <Modal isOpen={modelOpen} toggle={() => setModelOpen(false)}>
                <ModalHeader toggle={() => setModelOpen(false)}>
                    Item Details
                </ModalHeader>
                <ModalBody>
                    {loading &&
                        <RctSectionLoader />
                    }
                    <RctCollapsibleCard>
                        {ordersdetail && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell align="right">Qty</TableCell>
                                    <TableCell align="right">Rate ({'\u20B9'})</TableCell>
                                    <TableCell align="right">Amount ({'\u20B9'})</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ordersdetail && ordersdetail.map((orderdetail, key) => (
                                    (
                                        <TableRow key={key}>
                                            <TableCell>{orderdetail.itemCode} <br /> {orderdetail.itemName}</TableCell>
                                            <TableCell align='right'>{orderdetail.quantity}</TableCell>
                                            <TableCell align='right'>{orderdetail.rate}</TableCell>
                                            <TableCell align='right'>{orderdetail.amount}</TableCell>
                                        </TableRow>
                                    )
                                ))}
                            </TableBody>
                        </Table>}
                    </RctCollapsibleCard >
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default StockTransferReport;