import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';
// api
import api from 'api';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddNewItemForm() {
    const location = useLocation();
    const navigate = useNavigate()
    const id = location.state.id;
    const items = location.state.items;



    const [addNewItemDetail, setAddNewItemDetail] = useState({
        id: 0,
        name: '',
        slug: '',
        description: '',
        metaTitle: '',
        metaKeywords: '',
        metaDescription: '',
        displayOrder: 0,
        parentId: 0,
        includeInMenu: false,
        isPublished: true,
        status: 'Active',
        thumbnailImageUrl: null,
        thumbnailImage: null
    });
    const [loading, setLoading] = useState(false);

    console.log(addNewItemDetail);

    const addNewItem = async () => {
        if (addNewItemDetail.name === '') {
            NotificationManager.error("Category Required !");
            return;
        }
        const dataArray = new FormData();
        dataArray.append("name", addNewItemDetail.name);
        dataArray.append("slug", addNewItemDetail.slug);
        dataArray.append("description", addNewItemDetail.description);
        dataArray.append("metaTitle", addNewItemDetail.metaTitle);
        dataArray.append("metaKeywords", addNewItemDetail.metaKeywords);
        dataArray.append("metaDescription", addNewItemDetail.metaDescription);
        dataArray.append("displayOrder", addNewItemDetail.displayOrder);
        dataArray.append("parentId", addNewItemDetail.parentId);
        dataArray.append("includeInMenu", addNewItemDetail.includeInMenu);
        dataArray.append("isPublished", addNewItemDetail.isPublished);
        dataArray.append("thumbnailImage", addNewItemDetail.thumbnailImage);
        setLoading(true);
        await api.post('api/master/product-department', dataArray, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Category Added Successfully.");
                    navigate("/app/category-master-list");
                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                await api.get('api/master/product-department/' + id)
                    .then((response) => {

                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            id: response.data.prdGrpNo,
                            name: response.data.productGrp,
                            slug: response.data.slug == null ? "" : response.data.slug,
                            description: response.data.description ?? "",
                            metaTitle: response.data.metaTitle ?? "",
                            metaKeywords: response.data.metaKeywords ?? "",
                            metaDescription: response.data.metaDescription ?? "",
                            displayOrder: response.data.displayOrder ?? 0,
                            parentId: response.data.parentId ?? 0,
                            includeInMenu: response.data.includeInMenu ?? false,
                            isPublished: response.data.isPublished ?? false,
                            status: response.data.status,
                            thumbnailImageUrl: response.data.thumbnailImageUrl,
                            thumbnailImage: null
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }
    }, [id > 0]);

    const updateItem = async () => {
        if (addNewItemDetail.name === '') {
            NotificationManager.error("Category Required !");
            return;
        }
        const dataArray = new FormData();
        dataArray.append("id", addNewItemDetail.id);
        dataArray.append("name", addNewItemDetail.name);
        dataArray.append("slug", addNewItemDetail.slug);
        dataArray.append("description", addNewItemDetail.description);
        dataArray.append("metaTitle", addNewItemDetail.metaTitle);
        dataArray.append("metaKeywords", addNewItemDetail.metaKeywords);
        dataArray.append("metaDescription", addNewItemDetail.metaDescription);
        dataArray.append("displayOrder", addNewItemDetail.displayOrder);
        dataArray.append("parentId", addNewItemDetail.parentId);
        dataArray.append("includeInMenu", addNewItemDetail.includeInMenu);
        dataArray.append("isPublished", addNewItemDetail.isPublished);
        dataArray.append("thumbnailImage", addNewItemDetail.thumbnailImage);
        dataArray.append("status", addNewItemDetail.status);

        setLoading(true);
        await api.put('api/master/product-department', dataArray, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                NotificationManager.success("Category Updated Successfully.");
                navigate("/app/category-master-list");
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron POS | Category Master</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Category Master'}
            />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <div>
                    <FormControl fullWidth>
                        <TextField
                            type="text"
                            label="Name"
                            value={addNewItemDetail.name}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, name: e.target.value })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Slug"
                            value={addNewItemDetail.slug}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, slug: e.target.value })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Meta Title"
                            value={addNewItemDetail.metaTitle}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, metaTitle: e.target.value })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Meta Keywords"
                            value={addNewItemDetail.metaKeywords}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, metaKeywords: e.target.value })}
                            multiline
                            rows={3}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Meta Description"
                            value={addNewItemDetail.metaDescription}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, metaDescription: e.target.value })}
                            multiline
                            rows={3}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Parent Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            value={addNewItemDetail.parentId}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, parentId: e.target.value })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        >
                            <MenuItem selected key={0} value={0}>Top</MenuItem>
                            {
                                items && items.map((item, key) => (
                                    <MenuItem selected key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Description"
                            value={addNewItemDetail.description}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, description: e.target.value })}
                            multiline
                            rows={3}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Display Order"
                            type='number'
                            value={addNewItemDetail.displayOrder}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, displayOrder: e.target.value })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            label="Thumbnail Image"
                            type='file'
                            onChange={(e) => setAddNewItemDetail({
                                ...addNewItemDetail,
                                thumbnailImageUrl: URL.createObjectURL(e.target.files[0]),
                                thumbnailImage: e.target.files[0]
                            })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        />
                        <img src={addNewItemDetail.thumbnailImageUrl} alt="" height={200} width={200} />
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={addNewItemDetail.includeInMenu} onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, includeInMenu: e.target.value })} />} label="Include in Menu" />
                        <FormControlLabel control={<Checkbox value={addNewItemDetail.isPublished} onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, isPublished: e.target.value })} defaultChecked />} label="Is Published" />
                    </FormGroup>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            value={addNewItemDetail.status}
                            onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                            style={{ width: '100%', marginBottom: 15 }}
                            variant="standard"
                        >
                            <MenuItem selected key={0} value="Active">Active</MenuItem>
                            <MenuItem key={1} value="InActive">InActive</MenuItem>
                        </Select>
                    </FormControl>
                    <div className='row mt-2'>
                        <div className='col-md-12 text-right'>
                            {id == 0 ?
                                <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                                :
                                <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                            {'  '}
                            <Button variant="contained" color="error" className="text-white" onClick={() => navigate("/app/category-master-list")}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </RctCollapsibleCard>
        </div>
    )
}
