
import React, { useState, useEffect, useRef } from 'react';
import { useFetchMopMaster } from '../../hooks/useFetchData';
import { useNavigate, useLocation } from 'react-router-dom';

import { NotificationManager } from 'react-notifications';
import { Helmet } from "react-helmet";
import api from 'api';
// page title bar
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';

import { Button } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import ItemSelection from '../../component/CustomComponent/ItemSelection';
import ApplyDiscount from '../../component/CustomComponent/ApplyDiscount';
import ApplyReferralCode from '../../component/CustomComponent/ApplyReferralCode';
import SearchCustomer from '../../component/CustomComponent/SearchCustomer';
import MakePayment from '../../component/CustomComponent/MakePayment';
import AfterBillBox from '../../component/CustomComponent/AfterBillBox';

import useKeyboardShortcut from 'use-keyboard-shortcut';



import {
    Card,
    CardBody,
    CardHeader,
    CardFooter
} from 'reactstrap';
import ApplyCoupon from 'component/CustomComponent/ApplyCoupon';

export default function TakeAway() {
    const location = useLocation();
    const navigate = useNavigate()
    const { data: mopMaster } = useFetchMopMaster();
    const billDetailsInitialValue = {
        id: 0,
        partyID: 0,
        name: "",
        address1: "",
        address2: "",
        mobile: "",
        gstin: "",

        couponCode: null,
        referralCode: null,
        itemList: [],
        mopList: [],

        startSubmit: false,

        discPer: 0,
        discAmt: 0,
        netAmt: 0,
        paidAmount: 0
    };
    const [billDetails, setBillDetails] = useState(billDetailsInitialValue);

    const id = location.state.id;

    //console.log(billDetails);

    const savedBillDetailsInatialValue = {
        billID: 0,
        openAfterSale: false,
        referralCode: null
    };
    const [savedBillDetails, setSavedBillDetails] = useState(savedBillDetailsInatialValue);

    const { flushHeldKeys } = useKeyboardShortcut(
        ["Control", "F3"],
        shortcutKeys => handleSaveBill(),
        {
            overrideSystem: true,
            ignoreInputFields: false,
            repeatOnHold: false
        }
    );

    const refsItemSelection = useRef(null)
    const customerInputRef = useRef(null);
    const itemInputRef = useRef(null);

    useEffect(() => {
        if (billDetails.id == 0 && id > 0) {
            (async () => {
                setBillDetails({ ...billDetails, startSubmit: true });
                await api.get('api/sales/get-sale-entry?invoiceID=' + id)
                    .then((response) => {
                        console.log(response.data);
                        let temp = billDetailsInitialValue;
                        temp.id = response.data.id;
                        temp.partyID = response.data.partyId;
                        temp.name = response.data.name;
                        temp.address1 = response.data.address1;
                        temp.address2 = response.data.address2;
                        temp.mobile = response.data.mobile;
                        temp.gstin = response.data.gstin;
                        temp.couponCode = response.data.couponCode;
                        temp.referralCode = response.data.referralCode;
                        temp.startSubmit = false;
                        temp.discPer = 0;
                        temp.discAmt = 0;
                        temp.netAmt = response.data.netBillValue;
                        temp.paidAmount = response.data.paidAmount;

                        let arrs = [];
                        response.data.items.map((item, index) => {
                            arrs = arrs.concat([{
                                "id": item.id, "qty": parseFloat(item.qty) * -1, "itemCode": item.itemCode, "itemName": item.itemName, "mrp": item.mrp, "discPer": item.discPer,
                                "specialDiscAmt": item.splDiscAmt, "rate": item.rate, "taxable": item.taxable, "cgstper": item.cgstPer, "cgstamt": item.cgstAmt, "sgstper": item.sgstPer,
                                "sgstamt": item.sgstAmt, "igstper": item.igstPer, "igstamt": item.igstAmt, "cessper": item.cessPer, "cessamt": item.cessAmt, "productChildId": item.productChildId,
                                "remarks": item.remarks, "amount": item.amount, "incTax": item.incTax, "salesManId": item.salesManId
                            }]);
                        });
                        temp.itemList = arrs;



                        let mops = [];
                        response.data.mops.map((mop, index) => {
                            mops = mops.concat([{
                                "mopid": parseFloat(mop.mopId), "mopname": mop.mopName, "amount": mop.amount
                            }]);
                        });
                        temp.mopList = mops;

                        setBillDetails(temp);
                    })
                    .catch(error => {
                    }).finally(() => {
                        setBillDetails({ ...billDetails, startSubmit: false });
                    });
            })();
        }
    }, [id > 0]);

    useEffect(() => {
        if (refsItemSelection.current !== null) {
            refsItemSelection.current.applyDiscount(billDetails.itemList, billDetails.discPer, billDetails.discAmt);
        }
    }, [billDetails.mopList, billDetails.itemList, billDetails.discPer, billDetails.discAmt]);

    const handleSaveBill = async () => {
        if (billDetails.partyID == 0) {
            NotificationManager.error(`select customer to continue !`);
            return;
        }
        if (billDetails.itemList === undefined || billDetails.itemList === null
            || billDetails.itemList.length === 0) {
            NotificationManager.error("Select item to continue !");
            itemInputRef.current.focus();
            return;
        };
        if (billDetails.paidAmount != billDetails.netAmt) {
            NotificationManager.error(`Payable amount must be same to paid amount !`);
            return;
        }
        console.log(JSON.stringify(billDetails));

        setBillDetails({ ...billDetails, startSubmit: true });
        await api.post('api/sales/save-sale-bill', JSON.stringify(billDetails))
            .then((response) => {
                setSavedBillDetails({
                    ...savedBillDetails,
                    billID: response.data.id, openAfterSale: true, referralCode: billDetails.referralCode
                });

                setBillDetails(billDetailsInitialValue);

                NotificationManager.success("Bill Saved Successfully.");

            })
            .catch(error => {
                setBillDetails({ ...billDetails, startSubmit: false });
            }).finally(() => {
            });
    };

    const handleAfterSaleClose = async () => {
        setSavedBillDetails(savedBillDetailsInatialValue);
    };

    return (
        <div className="card-wrapper">
            <PageTitleBar title={'Billing'} />
            <Helmet>
                <title>Billing</title>
                <meta name="description" content="Billing" />
            </Helmet>

            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={savedBillDetails.openAfterSale}
            >
                <DialogContent>
                    <AfterBillBox savedBillDetails={savedBillDetails} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleAfterSaleClose()} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Card>
                <CardHeader color="warning">
                    <div className="row">
                        <div className="col-md-6">
                            <b>New Bill</b>
                        </div>
                        <div className="col-md-6 text-right">
                            <b><small>Bill Date : </small></b><small> {localStorage.getItem("DateOfSale")}</small>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <RctCollapsibleCard>
                        <div className="row">
                            <div className="col-md-10">
                                <SearchCustomer billDetails={billDetails} setBillDetails={setBillDetails} customerInputRef={customerInputRef} />
                            </div>
                        </div>
                    </RctCollapsibleCard>
                    <RctCollapsibleCard>
                        <div className="row">
                            <div className="col-md-12">
                                <ItemSelection ref={refsItemSelection} itemInputRef={itemInputRef} billDetails={billDetails} setBillDetails={setBillDetails} />
                            </div>
                        </div>
                    </RctCollapsibleCard>
                    <RctCollapsibleCard>
                        <div className='row'>
                            <div className="col-md-7">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <ApplyDiscount billDetails={billDetails} setBillDetails={setBillDetails} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {mopMaster && <MakePayment billDetails={billDetails} setBillDetails={setBillDetails} mops={mopMaster.data} />}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <ApplyCoupon billDetails={billDetails} setBillDetails={setBillDetails} />
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: 20 }}>
                                    <div className='col-md-12'>
                                        <ApplyReferralCode billDetails={billDetails} setBillDetails={setBillDetails} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RctCollapsibleCard>
                </CardBody>
                <CardFooter>
                    <div className="col-md-12 text-right">
                        <Button
                            color="danger"
                            onClick={handleSaveBill}
                            disabled={billDetails.startSubmit} >{billDetails.startSubmit ? 'Please wait...' : 'Save'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{billDetails.netAmt})</small>
                        </Button>
                        <Button style={{ marginLeft: 10 }} color="warning" className="text-white" onClick={() => setBillDetails(billDetailsInitialValue)}>Refresh</Button>
                        <Button style={{ marginLeft: 10 }} color="primary" className="text-white" onClick={() => navigate("/app/take-away-list")}>Cancel</Button>
                    </div>
                </CardFooter>
            </Card>
        </div >
    )
}
