import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';
// api
import api from 'api';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddNewItemForm() {
    const location = useLocation();
    const navigate = useNavigate()

    const [states, setStates] = useState([]);
    const [groups, setGroups] = useState(null);

    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        no: 0,
        accountCode: '',
        accountName: '',
        accountGroup: 0,
        registrationType: '',
        partyType: '',
        name: '',
        address1: '',
        address2: '',
        state: '',
        gstin: '',
        mobile: '',
        email: '',
        status: 'Active'
    });

    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.accountName === '') {
            NotificationManager.error("AccountName Required !");
            return;
        }
        if (addNewItemDetail.accountGroup === 0) {
            NotificationManager.error("AccountGroup Required !");
            return;
        }
        setLoading(true);
        await api.post('api/master/account', JSON.stringify(addNewItemDetail))
            .then((response) => {
                console.log(response.data);
                NotificationManager.success("Added Successfully.");
                navigate("/app/account-master-list");
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (addNewItemDetail.no == 0 && id > 0) {
            (async () => {
                await api.get('api/master/account/' + id)
                    .then((response) => {
                        console.log(response.data);

                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            no: response.data.no,
                            accountCode: response.data.accountCode,
                            accountName: response.data.accountName,
                            accountGroup: response.data.accountGroup,
                            registrationType: response.data.registrationType,
                            partyType: response.data.partyType,
                            name: response.data.name,
                            address1: response.data.address1,
                            address2: response.data.address2,
                            state: response.data.state,
                            gstin: response.data.gstin,
                            mobile: response.data.mobile,
                            email: response.data.email,
                            status: response.data.status
                        })
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error.response.data));
                    });
            })();
        }
    }, [id > 0]);

    const updateItem = async () => {
        if (addNewItemDetail.accountName === '') {
            NotificationManager.error("AccountName Required !");
            return;
        }
        if (addNewItemDetail.accountGroup === '') {
            NotificationManager.error("AccountGroup Required !");
            return;
        }

        setLoading(true);
        await api.put('api/master/account?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success("Updated Successfully.");
                navigate("/app/account-master-list");
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (groups === null) {
            (async () => {
                await api.get('api/master/account-group')
                    .then((response) => {
                        setGroups(response.data);
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error.response.data));
                    });
            })();
        }
        if (states.length == 0) {
            (async () => {
                await api.get('/api/master/state?PageNumber=1&PageSize=100&status=Active')
                    .then((response) => {
                        setStates(response.data.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            })();
        }
    })

    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron Salon | Account Master</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Account Master'}
            />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="Account Code"
                                value={addNewItemDetail.accountCode}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountCode: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                                autoFocus={true}
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                label="Account Name"
                                value={addNewItemDetail.accountName}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountName: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label">Account Group</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={addNewItemDetail.accountGroup}
                                variant="standard"
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountGroup: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                            >
                                <MenuItem value="0">Select Group</MenuItem>
                                {groups && groups.map((group) =>
                                    <MenuItem key={group.no} value={group.no}>{group.groupName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                label="Name"
                                value={addNewItemDetail.name}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, name: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="Addres1"
                                value={addNewItemDetail.address1}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, address1: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                label="Addres2"
                                value={addNewItemDetail.address2}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, address2: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel>State</InputLabel>
                            <Select
                                value={addNewItemDetail.state}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, state: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                {states && states.map((state) => <MenuItem key={state.stateName} value={state.stateName}>{state.stateName}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                label="GSTIN"
                                value={addNewItemDetail.gstin}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, gstin: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                type={'number'}
                                label="Mobile"
                                value={addNewItemDetail.mobile}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mobile: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                label="Email"
                                type={'email'}
                                value={addNewItemDetail.email}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, email: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={addNewItemDetail.status}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                <MenuItem selected key={0} value="Active">Active</MenuItem>
                                <MenuItem key={1} value="InActive">InActive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-12 text-right'>
                        {id == 0 ?
                            <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                            :
                            <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                        {'  '}
                        <Button variant="contained" color="error" className="text-white" onClick={() => navigate("/app/account-master-list")}>Cancel</Button>
                    </div>
                </div>

            </RctCollapsibleCard>
        </div>
    )
}
