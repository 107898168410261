import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import api from 'api';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import { notifyManager } from 'react-query';

export default function ApplyCoupon(props) {
    const [loading, setLoading] = useState(false);
    const [fetchSetting, setFetchSetting] = useState(false);

    useEffect(() => {
        if (fetchSetting == true) {
            (async () => {
                setLoading(true);
                await api.get('api/settings/get-referral-settings')
                    .then((response) => {
                        console.log(response.data);
                        var data = response.data;
                        props.setBillDetails({ ...props.billDetails, couponCode: "", discAmt: 0, discPer: data.discPerOnProduct });
                    })
                    .catch(error => {
                    }).finally(() => {
                        setLoading(false);
                    });
            })();
        }
    }, [fetchSetting]);

    const applyCoupon = async () => {
        if (props.billDetails.referralCode == null || props.billDetails.referralCode.length == 0) {
            props.setBillDetails({ ...props.billDetails, referralCode: "" });
            setFetchSetting(false);
            return;
        }
        setLoading(true);
        console.log("Apply refer");
        await api.get('api/sales/is-valid-referral-code?code=' + props.billDetails.referralCode)
            .then((response) => {
                console.log(response.data);
                var data = response.data;
                if (data != null || data != undefined) {
                    if (data == false) {
                        props.setBillDetails({ ...props.billDetails, referralCode: "" });
                        NotificationManager.error("Invalid referral code " + props.billDetails.referralCode);
                    }
                    else
                        setFetchSetting(true);
                }
            })
            .catch(error => {
                props.setBillDetails({ ...props.billDetails, referralCode: "" });
            })
            .finally(() => {
                setLoading(false);
            });

    }

    return (
        <div class="row">
            <TextField
                variant='standard'
                inputProps={{ style: { textAlign: 'center' } }}
                label="Referral Code (Optional)"
                value={props.billDetails.referralCode}
                onBlur={applyCoupon}
                onChange={(e) => props.setBillDetails({ ...props.billDetails, referralCode: e.target.value })}>
            </TextField>
            {loading &&
                <RctSectionLoader />
            }
        </div>

    )
}