import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';
// api
import api from 'api';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddNewItemForm() {
    const location = useLocation();
    const navigate = useNavigate();

    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        id: 0,
        salesPersonName: '',
        salesPersonCode: '',
        status: 'Active'
    });

    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.salesPersonName === '') {
            NotificationManager.error("Service Provider Name Required !");
            return;
        }
        if (addNewItemDetail.salesPersonCode === 0) {
            NotificationManager.error("Service Provider Code Required !");
            return;
        }
        setLoading(true);
        await api.post('api/master/sales-person-master', JSON.stringify(addNewItemDetail))
            .then((response) => {
                console.log(response.data);
                NotificationManager.success("Added Successfully.");
                navigate("/app/service-provider-master-list");
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                await api.get('api/master/sales-person-master/' + id)
                    .then((response) => {
                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            id: response.data.id,
                            salesPersonName: response.data.salesPersonName,
                            salesPersonCode: response.data.salesPersonCode,
                            status: response.data.status
                        })
                    })
                    .catch(error => {
                    });
            })();
        }
    }, [id > 0]);

    const updateItem = async () => {
        if (addNewItemDetail.salesPersonCode === '') {
            NotificationManager.error("Sales Person Code Required !");
            return;
        }
        if (addNewItemDetail.salesPersonName === '') {
            NotificationManager.error("Sales Person Name Required !");
            return;
        }
        console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.put('api/master/sales-person-master?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success("Updated Successfully.");
                navigate("/app/service-provider-master-list");
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }


    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron Salon | Service Provider Master</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Service Provider Master'}
            />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="Code"
                                value={addNewItemDetail.salesPersonCode}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, salesPersonCode: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                                autoFocus={true}
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                label="Name"
                                value={addNewItemDetail.salesPersonName}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, salesPersonName: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={addNewItemDetail.status}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                <MenuItem selected key={0} value="Active">Active</MenuItem>
                                <MenuItem key={1} value="InActive">InActive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-12 text-right'>
                        {id == 0 ?
                            <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                            :
                            <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                        {'  '}
                        <Button variant="contained" color="error" className="text-white" onClick={() => navigate("/app/service-provider-master-list")}>Cancel</Button>
                    </div>
                </div>

            </RctCollapsibleCard>
        </div>
    )
}
