import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import PointValueSettings from 'component/CustomComponent/PointValueSettings';
import ReferralSettings from 'component/CustomComponent/ReferralSettings';
import PrintQrCode from 'component/CustomComponent/PrintQrCodeScan2Order';
import SMSSettingsMaster from 'component/CustomComponent/SmsSettings';
import DayClosing from 'component/CustomComponent/DayClosing';
import PrintInfo from 'component/CustomComponent/PrintInfo';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <PageTitleBar title={'Settings'} />
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 2, borderColor: 'divider' }}
                >
                    <Tab label="Point Value Settings" {...a11yProps(0)} />
                    <Tab label="Referral Settings" {...a11yProps(1)} />
                    <Tab label="Print QRCode (Feedback)" {...a11yProps(2)} />
                    <Tab label="SMS Settings" {...a11yProps(3)} />
                    <Tab label="Day Closing" {...a11yProps(4)} />
                    <Tab label="Print Info" {...a11yProps(5)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <PointValueSettings />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReferralSettings />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <PrintQrCode />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <SMSSettingsMaster />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <DayClosing />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <PrintInfo />
                </TabPanel>
            </Box>
        </div >
    );
}
