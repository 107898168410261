import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
// api
import api from 'api';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

const AddNewItemForm = (props) => {
    const [addNewItemDetail, setAddNewItemDetail] = useState({
        brandGrp: '',
        status: 'Active'
    });
    const [loading, setLoading] = useState(false);

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail(prevState => arr);
    }

    const addNewItem = async () => {
        if (addNewItemDetail.brandGrp === '') {
            NotificationManager.error("Brand Required !");
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.post('api/master/product-brand', JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Brand Added Successfully.");

                    props.setAddNewItemModal(false);
                    props.setEditItem(null);

                    let itemsLocal = [...props.items];
                    itemsLocal.push(response.data);
                    props.setItems(itemsLocal);
                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                    setLoading(false);
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
                setLoading(false);
            });
    }

    const onAddUpdateItemModalClose = () => {
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    }

    useEffect(() => {
        setItemDetails();
    }, [props.editItem]);

    const setItemDetails = () => {
        if (props.editItem != null) {
            setAddNewItemDetail(props.editItem);
        }
    }


    const updateItem = async () => {
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error("Brand Required !");
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.put('api/master/product-brand?id=' + addNewItemDetail.brdGrpNo, JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Brand Updated Successfully.");

                    props.setAddNewItemModal(false);
                    props.setEditItem(null);

                    let indexOfUpdateItem = '';
                    let localItems = [...props.items];
                    //console.log(localItems);
                    for (let i = 0; i < localItems.length; i++) {
                        const item = localItems[i];
                        //console.log(`${item.id} === ${response.data.id}`);
                        if (item.id === response.data.id) {
                            indexOfUpdateItem = i;
                        }
                    }
                    //alert(indexOfUpdateItem);
                    localItems[indexOfUpdateItem] = response.data;
                    props.setItems(localItems);
                    setLoading(false);
                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                    setLoading(false);
                    console.log(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
                console.log(JSON.stringify(error));
                setLoading(false);
            });


        // const { editItem } = this.state;

        // let self = this;
        // setTimeout(() => {
        //     self.setState({ users, loading: false });
        //     NotificationManager.success('User Updated!');
        // }, 2000);
    }

    return (
        <Modal isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
            <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                {props.editItem === null ?
                    'Add New Brand' : 'Update Brand'
                }
            </ModalHeader>
            <ModalBody>
                {loading &&
                    <RctSectionLoader />
                }
                <Form>
                    <FormGroup>
                        <Label for="brand">Brand</Label>
                        <Input
                            type="text"
                            name="brand"
                            id="brand"
                            placeholder="Enter brand"
                            value={addNewItemDetail.brandGrp}
                            onChange={(e) => onChangeAddNewItemDetails('brandGrp', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="status">Status</Label>
                        <select id="status" className="form-control" value={addNewItemDetail.status} onChange={(e) => onChangeAddNewItemDetails('status', e.target.value)}>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                        </select>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {props.editItem === null ?
                    <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                    :
                    <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                {'  '}
                <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddNewItemForm;