import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    FormGroup
} from 'reactstrap';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

import api from 'api';
import { NotificationManager } from 'react-notifications';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
export default function PrintQrCode(props) {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedTable, setSelectedTable] = useState(0);

    useEffect(() => {
        getImage(0);
    }, []);

    const getImage = async (table) => {
        setSelectedTable(table);

        setLoading(true);
        await api.get('api/rest/kot/generate-qr-code?tableName=' + table)
            .then((response) => {
                if (response.status === 200) {
                    setImage(response.data);
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            }).finally(() => {
                setLoading(false);
            });
    }

    const onDownloadImage = () => {
        const downloadLink = document.createElement("a");
        downloadLink.href = image;
        downloadLink.download = "QRCode_" + selectedTable + "_" + localStorage.getItem("tenantID");
        downloadLink.click();
    }


    return (
        <div className="user-management">
            <PageTitleBar title={'Print QR Code (Feedback)'} />
            {loading &&
                <RctSectionLoader />
            }

            <RctCollapsibleCard>
                <Form>
                    <img src={image} height={300} width={300} alt="Select table to get image" />
                    <Button className="mb-10" color="primary" onClick={onDownloadImage}>Download</Button>

                </Form>
            </RctCollapsibleCard>

        </div >
    );
}
