/**
* Report Page
*/
import React, { useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@mui/material/Button';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
// page title bar
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import api from 'api';
// intl messages 
import { TextField } from '@mui/material';
// rct collapsible card
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { NotificationManager } from 'react-notifications';

const ChangePassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [pwdInfo, setPwdInfo] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const savePassword = async () => {
        if (pwdInfo.oldPassword.length === 0) {
            NotificationManager.error("Old password required !")
            return;
        }
        if (pwdInfo.newPassword.length === 0) {
            NotificationManager.error("New password required !")
            return;
        }
        if (pwdInfo.confirmPassword.length === 0) {
            NotificationManager.error("New confirm password required !")
            return;
        }
        setLoading(true);
        await api.post('api/master/login/change-password', JSON.stringify(pwdInfo))
            .then((response) => {
                NotificationManager.success("SUCCESS");
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    }

    const onChangePwdInfo = (key, value) => {
        var arr = { ...pwdInfo };
        arr[key] = value;
        setPwdInfo(prevState => arr);
    }

    return (
        <div className="formelements-wrapper">
            <PageTitleBar title={'Change Password'} match={props.match} />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <Form inline>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Old Password" fullWidth value={pwdInfo.oldPassword} onChange={(e) => onChangePwdInfo('oldPassword', e.target.value)} type="Password"
                        />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="New Password" fullWidth value={pwdInfo.newPassword} onChange={(e) => onChangePwdInfo('newPassword', e.target.value)} type="Password" />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="New Confirm Password" fullWidth value={pwdInfo.confirmPassword} onChange={(e) => onChangePwdInfo('confirmPassword', e.target.value)} type="Password" />
                    </FormGroup>

                    <Button variant="contained" className="text-white btn-danger" onClick={savePassword}>Change Password</Button>
                </Form>
            </RctCollapsibleCard>
        </div >
    )
}
export default ChangePassword;