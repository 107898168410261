import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    FormGroup
} from 'reactstrap';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';
// rct section loader
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

import api from 'api';
import { NotificationManager } from 'react-notifications';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@mui/material';

export default function PrintInfo(props) {
    const [printInfo, setPrintInfo] = useState({
        branchId: 0,
        documentFor: "Restaurant",
        billHeading: '',
        compName: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        address5: '',
        note1: '',
        printKot: false,
        printBill: true,
        billCopy: 1,
        kotcopy: 0,
        partyId: 0,
        defaultPartyAcGroup: 0,
        getItemFromBranchId: 0,
        defaultSaleWarehouseId: 0,
    });
    const [startSubmit, setStartSubmit] = useState(false);
    const [warehouses, setWarehouses] = useState([]);
    const [accountGroups, setAccountGroups] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        if (warehouses.length == 0)
            getWarehouseList();
        if (accountGroups.length == 0)
            getAccountGroupList();
        if (accounts.length == 0)
            getAccountList();
        if (branches.length == 0)
            getBranchList();

        getPrintInfo();
    }, []);

    const getWarehouseList = async () => {
        await api.get('api/master/warehouse')
            .then((response) => {
                setWarehouses(response.data);
            })
            .catch(error => {
                NotificationManager.error(error.message);
            });
    }

    const getAccountGroupList = async () => {
        await api.get('api/master/account-group')
            .then((response) => {
                setAccountGroups(response.data);
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            });
    }

    const getAccountList = async () => {
        await api.get('api/master/account?PageSize=100')
            .then((response) => {
                if (response.status === 200) {
                    setAccounts(response.data.data);
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            });
    }

    const getBranchList = async () => {
        await api.get('api/master/branch')
            .then((response) => {
                if (response.status === 200) {
                    setBranches(response.data);
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            });
    }

    const getPrintInfo = async () => {
        setStartSubmit(true);
        await api.get('api/settings/get-print-info?documentFor=Restaurant&BranchID=0')
            .then((response) => {
                if (response.status === 200) {
                    let localPrintInfo = { ...printInfo };
                    localPrintInfo = response.data;
                    setPrintInfo(localPrintInfo);
                }
                else {
                    var arr = { ...printInfo };
                    arr['branchId'] = 0;
                    arr['billHeading'] = '';
                    arr['compName'] = '';
                    arr['address1'] = '';
                    arr['address2'] = '';
                    arr['address3'] = '';
                    arr['address4'] = '';
                    arr['address5'] = '';
                    arr['note1'] = '';
                    arr['printKot'] = false;
                    arr['printBill'] = true;
                    arr['billCopy'] = 1;
                    arr['kotcopy'] = 0;
                    arr['partyId'] = 0;
                    arr['defaultPartyAcGroup'] = 0;
                    arr['getItemFromBranchId'] = 0;
                    arr['defaultSaleWarehouseId'] = 0;
                    setPrintInfo(prevState => arr);
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            })
            .finally(() => {
                setStartSubmit(false);
            });
    }

    const savePrintInfo = async () => {
        setStartSubmit(true);
        console.log(JSON.stringify(printInfo));
        await api.post('api/settings/set-print-info', JSON.stringify(printInfo))
            .then((response) => {
                if (response.status === 200) {
                    getPrintInfo(printInfo.branchId);
                    NotificationManager.success("SUCCESS");
                    setStartSubmit(false);
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                    setStartSubmit(false);
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
                setStartSubmit(false);
            });
    }

    const onChangePrintInfo = (key, value) => {
        var arr = { ...printInfo };
        arr[key] = value;
        setPrintInfo(prevState => arr);
        if (key === "branchId") {
            getPrintInfo(value);
        }
    }


    return (
        <div className="user-management">
            <PageTitleBar title={'Print Info'} match={props.match} />
            {startSubmit &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <Form>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Bill Heading" fullWidth value={printInfo.billHeading} onChange={(e) => onChangePrintInfo('billHeading', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Restaurant Name" fullWidth value={printInfo.compName} onChange={(e) => onChangePrintInfo('compName', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Address1" fullWidth value={printInfo.address1} onChange={(e) => onChangePrintInfo('address1', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Address2" fullWidth value={printInfo.address2} onChange={(e) => onChangePrintInfo('address2', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Address3" fullWidth value={printInfo.address3} onChange={(e) => onChangePrintInfo('address3', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Address4" fullWidth value={printInfo.address4} onChange={(e) => onChangePrintInfo('address4', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Address5" fullWidth value={printInfo.address5} onChange={(e) => onChangePrintInfo('address5', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Note" fullWidth multiline value={printInfo.note1} onChange={(e) => onChangePrintInfo('note1', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-label">Get Item From Branch</InputLabel>
                        <Select
                            labelId="demo-simple-select-label" fullWidth
                            id="demo-simple-select"
                            value={printInfo.getItemFromBranchId}
                            onChange={(e) => onChangePrintInfo('getItemFromBranchId', e.target.value)}
                        >
                            {branches && branches.map((branch) =>
                                <MenuItem key={branch.id} value={branch.id}>{branch.branchCode}</MenuItem>
                            )}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-label">Default Sale Warehouse</InputLabel>
                        <Select
                            labelId="demo-simple-select-label" fullWidth
                            id="demo-simple-select"
                            value={printInfo.defaultSaleWarehouseId}
                            onChange={(e) => onChangePrintInfo('defaultSaleWarehouseId', e.target.value)}
                        >
                            {warehouses && warehouses.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-label">Default A/c Group</InputLabel>
                        <Select
                            labelId="demo-simple-select-label" fullWidth
                            id="demo-simple-select"
                            value={printInfo.defaultPartyAcGroup}
                            onChange={(e) => onChangePrintInfo('defaultPartyAcGroup', e.target.value)}
                        >
                            {accountGroups && accountGroups.map((accountGroup) => <MenuItem key={accountGroup.no} value={accountGroup.no}>{accountGroup.groupName}</MenuItem>)}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-label">Default Party</InputLabel>
                        <Select
                            labelId="demo-simple-select-label" fullWidth
                            id="demo-simple-select"
                            value={printInfo.partyId}
                            onChange={(e) => onChangePrintInfo('partyId', e.target.value)}
                        >
                            {accounts && accounts.map((accountGroup) => <MenuItem key={accountGroup.id} value={accountGroup.id}>{accountGroup.accountName}</MenuItem>)}
                        </Select>
                    </FormGroup>
                    <Button className="mb-10" color="primary" onClick={savePrintInfo} disabled={startSubmit}>{startSubmit ? 'Please wait...' : 'Save'}</Button>

                </Form>
            </RctCollapsibleCard>

        </div>
    );
}
