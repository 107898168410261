import React, { useState, useEffect } from 'react';
import RctCollapsibleCard from 'component/RctCollapsibleCard/RctCollapsibleCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TextField, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { NotificationManager } from 'react-notifications';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import api from 'api';
import RctSectionLoader from 'component/RctSectionLoader/RctSectionLoader';
import { useFetchServiceProviderMaster } from '../../hooks/useFetchData'

export default function ServiceProvider(props) {
    const { data: itemDatas, isLoading: loading } = useFetchServiceProviderMaster()

    const onChangeServiceProvider = (serviceProviderId) => {
        let arrs = [...props.billDetails.itemList];
        const elementsIndex = arrs.findIndex(element => element["productChildId"] == props.productChildId);
        if (elementsIndex != -1) {
            arrs[elementsIndex]["salesManId"] = serviceProviderId;
        }
        props.setBillDetails({ ...props.billDetails, itemList: arrs });
    };

    return (
        <FormControl sx={{ width: "200px;" }} size="medium">
            <InputLabel id="demo-simple-select-label">Service Provider</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.salesManId}
                label="Service Provider"
                onChange={(e) => onChangeServiceProvider(e.target.value)}
            >
                {itemDatas && itemDatas.data.map((item) =>
                    <MenuItem selected key={item.id} value={item.id}>{item.salesPersonCode}</MenuItem>
                )}
            </Select>
        </FormControl>

    )
}