import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    FormGroup,
} from 'reactstrap';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

import api from 'api';
import { NotificationManager } from 'react-notifications';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import { TextField } from '@mui/material';
export default function SMSSettingsMaster(props) {
    const [section, setSection] = useState("RestaurantSalebill");
    const InitialSMSSettings = {
        sms: '',
        emailSubject: '',
        email: '',
        section: section,
        sendSmsonTransaction: false,
        smsapi: '',
        emailApi: ''
    };
    const [smsSettings, setSmsSettings] = useState(InitialSMSSettings);
    const [startSubmit, setStartSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSMSSettings()
    }, [section]);

    const getSMSSettings = async () => {
        setLoading(true);
        await api.get('api/settings/get-sms-settings?section=' + section)
            .then((response) => {
                console.log(response.data);
                setSmsSettings(response.data);
            })
            .catch(error => {
                setSmsSettings(InitialSMSSettings);
            }).finally(() => {
                setLoading(false);
            });
    }

    const saveSMSSettings = async () => {
        if (smsSettings.section == "0") {
            NotificationManager.error("Select Section !");
            return;
        }
        setStartSubmit(true);
        await api.post('api/settings/set-sms-settings', JSON.stringify(smsSettings))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("SUCCESS");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
            }).finally(() => {
                setStartSubmit(false);
            });
    }

    return (
        <div className="formelements-wrapper">
            <PageTitleBar title={'SMS Template Settings.......................................'} />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <FormGroup className='col-md-12'>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={section}
                        onChange={(e) => setSection(e.target.value)}
                        variant='standard'
                        fullWidth
                    >
                        <MenuItem key="RestaurantSalebill" value="RestaurantSalebill">Sale Bill (Customer)</MenuItem>
                        <MenuItem key="RestaurantSalebillReferral" value="RestaurantSalebillReferral">Sale Bill (Referral)</MenuItem>
                        <MenuItem key="RestaurantSalebillReferralCodeUsed" value="RestaurantSalebillReferralCodeUsed">Sale Bill (Referral-Used)</MenuItem>
                    </Select>
                </FormGroup>
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <Form>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="SMS Template" fullWidth value={smsSettings.sms}
                            multiline={true} rows={4}
                            onChange={(e) => setSmsSettings({ ...smsSettings, sms: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="SMS API" fullWidth value={smsSettings.smsapi}
                            multiline={true} rows={4}
                            onChange={(e) => setSmsSettings({ ...smsSettings, smsapi: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Email Subject" fullWidth value={smsSettings.emailSubject}
                            onChange={(e) => setSmsSettings({ ...smsSettings, emailSubject: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Email Body Template" fullWidth value={smsSettings.email}
                            multiline={true} rows={4}
                            onChange={(e) => setSmsSettings({ ...smsSettings, email: e.target.value })} />
                    </FormGroup>
                    <FormGroup className="mr-10 mb-10">
                        <TextField label="Email API" fullWidth value={smsSettings.emailApi}
                            multiline={true} rows={4}
                            onChange={(e) => setSmsSettings({ ...smsSettings, emailApi: e.target.value })} />
                    </FormGroup>
                    <Button className="mb-10" color="primary" onClick={saveSMSSettings} disabled={startSubmit}>{startSubmit ? 'Please wait...' : 'Save'}</Button>
                </Form>
            </RctCollapsibleCard>

        </div>
    );
}
