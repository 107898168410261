import React, { useState, useEffect } from "react";
import api from 'api';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from 'react-notifications';

const ImportItems = (props) => {
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);

    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };

    const uploadFile = (file, onUploadProgress) => {
        let formData = new FormData();

        formData.append("file", file);

        return api.post("api/master/product/import-product", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    };

    const upload = () => {
        let currentFile = selectedFiles[0];

        setProgress(0);
        setCurrentFile(currentFile);

        uploadFile(currentFile, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {
            })
            .then((files) => {
                NotificationManager.success("Success");
            })
            .catch(() => {
                setProgress(0);
                NotificationManager.error("Could not upload the file!");
                setCurrentFile(undefined);
            });

        setSelectedFiles(undefined);
    };

    const onImportItemModalClose = () => {
        props.setImportItemModal(false);
    }

    return (

        <div>
            <Modal isOpen={props.importItemModal} toggle={() => onImportItemModalClose()}>
                <ModalHeader toggle={() => onImportItemModalClose()}>
                    Import Items
                </ModalHeader>
                <ModalBody>
                    {currentFile && (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: progress + "%" }}
                            >
                                {progress}%
                            </div>
                        </div>
                    )}

                    <label className="btn btn-default">
                        <input type="file" onChange={selectFile} />
                    </label>

                    <button
                        className="btn btn-success"
                        disabled={!selectedFiles}
                        onClick={upload}
                    >
                        Upload
                    </button>


                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>

        </div>
    );
};

export default ImportItems;