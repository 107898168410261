// assets
import { IconChartInfographic, IconKey, IconSettings, IconTools } from '@tabler/icons';

// constant
const icons = {
    IconKey, IconChartInfographic
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const reports = {
    id: 'reports',
    title: 'Reports',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            icon: icons.IconChartInfographic,
            children: [
                {
                    id: 'billtaxreports',
                    title: 'Bill Tax Report',
                    type: 'item',
                    url: '/app/bill-tax-report',
                    breadcrumbs: false
                },
                {
                    id: 'itemreport',
                    title: 'Item Report',
                    type: 'item',
                    url: '/app/item-report',
                    breadcrumbs: false
                },
                {
                    id: 'stockreport',
                    title: 'Stock Report',
                    type: 'item',
                    url: '/app/stock-report',
                    breadcrumbs: false
                },
                {
                    id: 'stocktransferreport',
                    title: 'Stock Transfer Report',
                    type: 'item',
                    url: '/app/stock-transfer-report',
                    breadcrumbs: false
                },
                ,
                {
                    id: 'brandcategoryregister',
                    title: 'Brand Category Register',
                    type: 'item',
                    url: '/app/brand-category-report',
                    breadcrumbs: false
                },
                // {
                //        id: 'stockreportmain',
                //             title: 'Stock',
                //             type: 'collapse',
                //             icon: icons.IconSettings,
                //             children: [
                //                 {
                //                     id: 'stockreportchild',
                //                     title: 'Stock Report',
                //                     type: 'item',
                //                     url: '/app/bill-tax-report',
                //                     breadcrumbs: false
                //                 }
                //             ]
                                            
                // }
            ]
        }
    ]
};

export default reports;
