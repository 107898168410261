import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'api';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@mui/material/LinearProgress';

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const navigate = useNavigate();


    const handleLogin = async (formValue, setLoading) => {
        setLoading(true);
        await api.post('api/TOKEN', formValue)
            .then((response) => {
                let expiresAt = JSON.stringify((response.data.expiration * 1000) + new Date().getTime());
                localStorage.setItem('access_token', response.data.token);
                localStorage.setItem('expires_at', expiresAt);
                localStorage.setItem("user_id", response.data.username);
                localStorage.setItem("tenantID", formValue.tenantID);
                window.location.href = "/";
                NotificationManager.success('User Login Successfully!');
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_id');
        localStorage.removeItem('tenantID');
        navigate("/login");

    };

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};