/**
* Report Page
*/
import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Form } from 'reactstrap';
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';

import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';



// page title bar
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import api from 'api';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { TextField } from '@mui/material';
// rct collapsible card
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { NotificationManager } from 'react-notifications';
var moment = require('moment');

const BillTaxReport = (props) => { 
    const [todayBillCount, setTodayBillCount] = useState(0);
    const [todayTotalSale, setTodayTotalSale] = useState(0);
    const [yesterdayBillCount, setYesterdayBillCount] = useState(0);
    const [yesterdayTotalSale, setYesterdayTotalSale] = useState(0);

    const [sevendayBillCount, setSevendayBillCount] = useState(0);
    const [sevendayTotalSale, setSevendayTotalSale] = useState(0);

    const [thirtydayBillCount, setThirtydayBillCount] = useState(0);
    const [thirtydayTotalSale, setThirtydayTotalSale] = useState(0);

    const [ninetydayBillCount, setNinetydayBillCount] = useState(0);
    const [ninetydayTotalSale, setNinetydayTotalSale] = useState(0);

    const [orders, setOrders] = useState(null);

    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);


    const [mopDatas, setMopDatas] = useState(undefined);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

     

    useEffect(() => { 
        (async () => {
            setLoading(true);
            await api.get('api/rest/report/get-sale-summary?valueFor=0')
                .then((response) => {
                    setTodayTotalSale(response.data[0].total);
                    setTodayBillCount(response.data[0].count)
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api.get('api/rest/report/get-sale-summary?valueFor=1')
                .then((response) => {
                    setYesterdayTotalSale(response.data[0].total);
                    setYesterdayBillCount(response.data[0].count)
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api.get('api/rest/report/get-sale-summary?valueFor=30')
                .then((response) => {
                    setThirtydayTotalSale(response.data[0].total);
                    setThirtydayBillCount(response.data[0].count)
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api.get('api/rest/report/get-sale-summary?valueFor=90')
                .then((response) => {
                    setNinetydayTotalSale(response.data[0].total);
                    setNinetydayBillCount(response.data[0].count)
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();

        (async () => {
            await api.get('api/rest/report/get-sale-summary?valueFor=7')
                .then((response) => {
                    setSevendayTotalSale(response.data[0].total);
                    setSevendayBillCount(response.data[0].count)
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();



        (async () => {
            loadData(1);
        })();

        (async () => {
            loadMOPData();
        })();

    }, []);


    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);


    const loadData = async () => {
        setLoading(true);
        await api.get('api/rest/report/get-billtax-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
            params: {
                startDate: startDate,
                endDate: endDate,
                searchText: searchText
            }
        })
            .then((response) => {
                setCount(response.data.totalRecords);
                setOrders(response.data.data);
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const exportReport = async () => {
        setLoading(true);
        await api.get('api/rest/report/get-billtax-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
            params: {
                startDate: startDate,
                endDate: endDate,
                searchText: searchText,
                export: true
            }
        })
            .then((msg) => {
                console.log(msg);
                var a = document.createElement("a");
                a.href = msg.data;
                a.download = "BillTaxRegister.xlsx";
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const loadMOPData = async () => {
        setLoading(true);
        await api.get('api/rest/report/get-mop-report', {
            params: {
                startDate: startDate,
                endDate: endDate,
            }
        })
            .then((response) => {
                console.log(response.data);
                setMopDatas(response.data);
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleChangePage = async (newPage) => {
        //setPage(page + 1); 
        console.log("page........");
        console.log("New Page : " + newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        console.log("Rows per page changed !");
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 

    function TablePaginationActions() {

        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }
 
    return ( 
        <>
            <Helmet>
                <title>BillTaxReport</title>
                <meta name="description" content="BillTax Reports" />
            </Helmet>
            <PageTitleBar title='Bill Tax Report' match='app/billtaxreport' />
            {loading &&
                <RctSectionLoader />
            }

            <RctCollapsibleCard>
                <div className="row">
                        <div className="col-md-2 mt-10">
                                <TextField
                                        type="date"
                                        label="Start Date"
                                        value={moment(startDate).format("YYYY-MM-DD")}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        /> 
                        </div>
                        <div className="col-md-2 mt-10">
                                <TextField
                                        type="date"
                                        label="End Date"
                                        value={moment(endDate).format("YYYY-MM-DD")}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        /> 
                        </div>
                        <div className="col-md-3 mt-10">
                            <TextField
                            style={{width : '100%'}}
                                        type="text"
                                        label="Search Text"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        /> 
                        </div>
                        <div className='col-md-1 mt-10'> 
                            <Button color="primary" variant="contained" className="mr-10 text-white" onClick={() => loadData(0)}>Search</Button>    
                        </div>
                        <div className='col-md-1 mt-10'> 
                            <Button color='success' variant="contained" className="mr-10 text-white" onClick={exportReport}>Export</Button>                  
                        </div>   
                </div> 
            </RctCollapsibleCard>

            <RctCollapsibleCard>
                    <div className='table-responsive'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="top-filter clearfix p-20">
                                        <div style={{ margin: 25 }}>
                                            <ul className="list-inline d-flex align-content-center">
                                                <li className="list-inline-item col">
                                                    <h4>Today</h4>
                                                    <h3 className="title">{'\u20B9'}{todayTotalSale}</h3>
                                                    <h4>{todayBillCount} Order</h4>
                                                </li>
                                                <li className="list-inline-item col">
                                                    <h4>Yesterday</h4>
                                                    <h3 className="title">{'\u20B9'}{yesterdayTotalSale}</h3>
                                                    <h4>{yesterdayBillCount} Order</h4>
                                                </li>
                                                <li className="list-inline-item col">
                                                    <h4>Last 7 Days</h4>
                                                    <h3 className="title">{'\u20B9'}{sevendayTotalSale}</h3>
                                                    <h4>{sevendayBillCount} Order</h4>
                                                </li>
                                                <li className="list-inline-item col">
                                                    <h4>Last 30 Days</h4>
                                                    <h2 className="title">{'\u20B9'}{thirtydayTotalSale}</h2>
                                                    <h4>{thirtydayBillCount} Orders</h4>
                                                </li>
                                                <li className="list-inline-item col">
                                                    <h4>Last 90 Days</h4>
                                                    <h2 className="title">{'\u20B9'}{ninetydayTotalSale}</h2>
                                                    <h4>{ninetydayBillCount} orders</h4>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className='table-responsive'>
                        {orders && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order Type</TableCell>
                                    <TableCell>Order No</TableCell>
                                    <TableCell>Invoice No</TableCell>
                                    <TableCell>Order date</TableCell>
                                    <TableCell align="right">Amount ({'\u20B9'})</TableCell>
                                    <TableCell align="right">Paid ({'\u20B9'})</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Customer</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders && orders.map((order, key) => (
                                    (order.orderType != null ?
                                        <TableRow key={key}>
                                            <TableCell>{order.orderType}</TableCell>
                                            <TableCell>{order.orderNo}</TableCell>
                                            <TableCell> {order.invoiceNo}</TableCell>
                                            <TableCell><div className="media-left mr-15">
                                                {moment(order.invoiceDate).format("DD-MMM-YYYY hh:mm a")}
                                            </div>
                                            </TableCell>
                                            <TableCell align='right'>{order.netAmount}</TableCell>
                                            <TableCell align='right'>{order.paid} - ({order.mop})</TableCell>
                                            <TableCell>
                                                <span className={order.status == "Cancel" ? "badge badge-danger" : "badge badge-primary"}>{order.status}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{order.customerDetails}
                                                </span>
                                            </TableCell>
                                        </TableRow> : null)
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell align='right'>Total :  </TableCell>
                                    <TableCell>{orders[orders.length - 1].netAmount}</TableCell>
                                    <TableCell align='right'>{orders[orders.length - 1].paid}</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TablePagination
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                        labelRowsPerPage={<span>Rows:</span>}
                                        labelDisplayedRows={({ page }) => {
                                            return `Page: ${page + 1}`;
                                        }}
                                        backIconButtonProps={{
                                            color: "secondary"
                                        }}
                                        nextIconButtonProps={{ color: "secondary" }}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "page number"
                                            }
                                        }}
                                        showFirstButton={true}
                                        showLastButton={true}
                                        ActionsComponent={TablePaginationActions}
                                    //component={Box}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>}

                        <Table className="table table-hover">
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>MOP</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>Amount ({'\u20B9'})</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mopDatas && mopDatas.map((order, key) => (
                                    <TableRow key={key}>
                                        <TableCell>
                                            <div className="media">
                                                <div className="media-left mr-15">
                                                    {order.userName}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="media">
                                                <div className="media-left mr-15">
                                                    {order.mopname}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='right'>{order.amount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow style={{ backgroundColor: '#000', color: "#fff" }}>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell align='right'>{(mopDatas && mopDatas.reduce((a, v) => a = a + v.amount, 0))} ({'\u20B9'})</TableCell>
                                    
                                </TableRow>
                            </TableFooter>
                        </Table>
                </div>      
            </RctCollapsibleCard >
            </>
    )
}

export default BillTaxReport;