import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
// api
import api from 'api';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import { Link } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';


const AddNewItemForm = (props) => {
    const [addNewItemDetail, setAddNewItemDetail] = useState({
        itemType: "Goods",
        departmentId: 0,
        brandId: 0,
        id: 0,
        itemCode: '',
        itemName: '',
        hsncode: '',
        mrp: 0,
        redTag: 0,
        openItem: 0,
        incTax: 0,
        status: 'Active',
        counterId: 0,
        GenerateChildId: "No",
        discountable: true,
        saleable: 1,
        cgstid: 0, 
        unitId: 1,
        taxibility: "Taxable",
        point: 0

    });
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState(null);
    const [brands, setBrands] = useState(null);
    const [gsts, setGsts] = useState(null);

    const onChangeAddNewItemDetails = (key, value) => {
        var arr = { ...addNewItemDetail };
        arr[key] = value;
        setAddNewItemDetail(prevState => arr);
    }

    const addNewItem = async () => {
        if (addNewItemDetail.departmentId === 0) {
            NotificationManager.error("Category Required !");
            return;
        }
        if (addNewItemDetail.brandId === 0) {
            NotificationManager.error("Brand Required !");
            return;
        }
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error("Item Code Required !");
            return;
        }
        if (addNewItemDetail.itemName === '') {
            NotificationManager.error("Item Name Required !");
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.post('api/master/product', JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Item Added Successfully.");

                    props.setAddNewItemModal(false);
                    props.setEditItem(null);

                    props.loadItems();


                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onAddUpdateItemModalClose = () => {
        setAddNewItemDetail({
            itemType: "Goods",
            departmentId: 0,
            brandId: 0,
            id: 0,
            itemCode: '',
            itemName: '',
            hsncode: '',
            mrp: 0,
            redTag: 0,
            openItem: 0,
            incTax: 0,
            status: 'Active',
            counterId: 0,
            GenerateChildId: "No",
            discountable: true,
            saleable: 1,
            cgstid: 0, 
            unitId: 1,
            taxibility: "Taxable",

        });
        props.setAddNewItemModal(false);
        props.setEditItem(null);
    }

    useEffect(() => {
        if (departments === null) {
            (async () => {
                await api.get('api/master/product-department')
                    .then((response) => {
                        setDepartments(response.data);
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error));
                    });
            })();
        }

        (async () => {
            await api.get('api/master/product-brand')
                .then((response) => {
                    console.log(response.data);
                    setBrands(response.data.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();

        (async () => {
            await api.get('api/master/gst-master')
                .then((response) => {
                    setGsts(response.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();

        (async () => {
            await setItemDetails();
        })();


    }, [props.editItem]);

    const setItemDetails = async () => {
        console.log(props.editItem);
        if (props.editItem != null) {
            await api.get('api/master/product/' + props.editItem.id)
                .then((response) => {
                    console.log(response.data);
                    setAddNewItemDetail({
                        itemType: response.data.itemType,
                        departmentId: response.data.departmentId,
                        brandId: response.data.brandId,
                        id: response.data.id,
                        itemCode: response.data.itemCode,
                        itemName: response.data.itemName,
                        hsncode: response.data.hsncode,
                        mrp: response.data.mrp,
                        redTag: response.data.redTag,
                        openItem: response.data.openItem,
                        incTax: response.data.incTax,
                        status: response.data.status,
                        counterId: response.data.counterId,
                        GenerateChildId: "No",
                        discountable: response.data.discountable,
                        saleable: 1,
                        cgstid: response.data.cgstid, 
                        unitId: 1,
                        taxibility: "Taxable",
                        point: response.data.point
                    }

                    );
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });



            // if (props.editItem.redTag === 1) {

            // }
            // setAddNewItemDetail(props.editItem);
        }
    }


    const updateItem = async () => {
        if (addNewItemDetail.itemCode === '') {
            NotificationManager.error("Item Code Required !");
            return;
        }
        if (addNewItemDetail.itemName === '') {
            NotificationManager.error("Item Name Required !");
            return;
        }
        console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api.put('api/master/product?id=' + addNewItemDetail.id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Item Updated Successfully.");

                    props.setAddNewItemModal(false);
                    props.setEditItem(null);


                    props.loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response));
                    console.log(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
                console.log(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });


        // const { editItem } = this.state;

        // let self = this;
        // setTimeout(() => {
        //     self.setState({ users, loading: false });
        //     NotificationManager.success('User Updated!');
        // }, 2000);
    }

    return (
        <Modal className='modal-lg' isOpen={props.addNewItemModal} toggle={() => onAddUpdateItemModalClose()}>
            <ModalHeader toggle={() => onAddUpdateItemModalClose()}>
                {props.editItem === null ?
                    'Add New Item' : 'Update Item'
                }
            </ModalHeader>
            <ModalBody>
                {loading &&
                    <RctSectionLoader />
                }
                <Form className='row'>
                    <FormGroup className='col-lg-6'>
                        <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label">Category<Link to={{ pathname: "/app/category-master-list" }}>+</Link></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addNewItemDetail.departmentId}
                                label="Category"
                                onChange={(e) => onChangeAddNewItemDetails('departmentId', e.target.value)}
                            >
                                <MenuItem value="0">Select Category</MenuItem>
                                {departments && departments.map((department) =>
                                    <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className='col-lg-6'>
                        <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label">Brand<Link to={{ pathname: "/app/brand-master" }}>+</Link></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addNewItemDetail.brandId}
                                label="Brand"
                                onChange={(e) => onChangeAddNewItemDetails('brandId', e.target.value)}
                            >
                                <MenuItem value="0">Select Brand</MenuItem>
                                {brands && brands.map((brand) =>
                                    <MenuItem key={brand.brdGrpNo} value={brand.brdGrpNo}>{brand.brandGrp}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className='col-lg-4'>
                        <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label">GST</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addNewItemDetail.cgstid}
                                label="CGST"
                                onChange={(e) => onChangeAddNewItemDetails('cgstid', e.target.value)}
                            >
                                <MenuItem value="0">Select GST</MenuItem>
                                {gsts && gsts.map((gst) =>
                                    <MenuItem key={gst.id} value={gst.id}>{gst.gstname}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </FormGroup>                   
                    <FormGroup className='col-lg-6'>
                    </FormGroup>
                    <FormGroup className='col-lg-6'>
                        <TextField fullWidth label="Item Code"
                            size="small"
                            value={addNewItemDetail.itemCode}
                            onChange={(e) => onChangeAddNewItemDetails('itemCode', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className='col-lg-6'>
                        <TextField fullWidth label="Item Name"
                            size="small"
                            value={addNewItemDetail.itemName}
                            onChange={(e) => onChangeAddNewItemDetails('itemName', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className='col-lg-6'>
                        <TextField fullWidth label="HSN Code"
                            size="small"
                            value={addNewItemDetail.hsncode}
                            onChange={(e) => onChangeAddNewItemDetails('hsncode', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className='col-lg-3'>
                        <TextField fullWidth label="Price"
                            type={'number'}
                            size="small"
                            value={addNewItemDetail.mrp}
                            onChange={(e) => onChangeAddNewItemDetails('mrp', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className='col-lg-3'>
                        <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addNewItemDetail.status}
                                label="Status"
                                onChange={(e) => onChangeAddNewItemDetails('status', e.target.value)}
                            >
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="InActive">InActive</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className='col-lg-3'>
                        <FormControl sx={{ width: "100%" }} size="small">
                            <TextField fullWidth label="Point"
                            type='number'
                                size="small"
                                value={addNewItemDetail.point}
                                onChange={(e) => onChangeAddNewItemDetails('point', e.target.value)}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup className='col-lg-4'>
                        <Label for="openItem">Open Item</Label>
                        <Switch checked={addNewItemDetail.openItem === 1} aria-label="checkedB" onChange={(e) => onChangeAddNewItemDetails('openItem', addNewItemDetail.openItem === 0 ? 1 : 0)}
                        />
                    </FormGroup>
                    <FormGroup className='col-lg-4'>
                        <Label for="incTax">Inclusive Tax</Label>
                        <Switch checked={addNewItemDetail.incTax === 1} aria-label="checkedB" onChange={(e) => onChangeAddNewItemDetails('incTax', addNewItemDetail.incTax === 0 ? 1 : 0)}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {/* {editItem === null ?
                <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                : <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>
            }
            {' '}
            <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button> */}
                {props.editItem === null ?
                    <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                    :
                    <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                {'  '}
                <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateItemModalClose()}>Cancel</Button>
            </ModalFooter>
        </Modal >
    )
}
export default AddNewItemForm;