import { useQuery } from 'react-query';
import api from 'api';

export const useFetchMopMaster = (onSuccess, onError) => {
    return useQuery('get-mop-master',
        () => {
            return api.get('api/rest/kot/get-mop-master')
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720, // 12 hours
        }
    )
}

export const useFetchItemMaster = (onSuccess, onError) => {
    return useQuery('get-item-master-sale',
        () => {
            return api.get('api/rest/kot/get-item-list')
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720, // 12 hours
        }
    )
}

export const useFetchCustomerMaster = (onSuccess, onError) => {
    return useQuery('get-customer-master-sale',
        () => {
            return api.get('api/rest/kot-bill/get-customer-list')
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720, // 12 hours
        }
    )
}

export const useFetchServiceProviderMaster = (onSuccess, onError) => {
    return useQuery('get-service-provider-master',
        () => {
            return api.get('api/master/sales-person-master')
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: onSuccess,
            cacheTime: 60 * 1000 * 720, // 12 hours
            staleTime: 60 * 1000 * 720, // 12 hours
        }
    )
}