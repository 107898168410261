import React, { useState, useEffect, Fragment, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import api from 'api';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material'; 
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import { Helmet } from "react-helmet";
import { NotificationManager } from 'react-notifications';

import Button from '@mui/material/Button';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

import Box from "@mui/material/Box";

const StockTransfer = (props) => {
    const location = useLocation();

    const itemCodeRef = useRef(null);
    const qtyRef = useRef(null);
    const warehouseFromRef = useRef(null);
    const warehouseToRef = useRef(null); 

    const [itemsList, setItemsList] = useState([]);
    const [warehouses, setWarehouses] = useState([]); 
 

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const itemInitialValue = { 
        productChildId: '', 
        qty: 0,
        itemCode:'',
        itemName:'',
        mrp:0
    };
    const [item, setItem] = useState(itemInitialValue);
    const initialValue = {
        id: 0,
        docDate: moment(new Date().toLocaleString()).format("YYYY-MM-DD"),
        docHeader: "Issue",
        spTag: "ST",
        godownFromID: 0,
        godownToID:0,
        remarks: "",
        items:[],
        netValue:0,
        totalQty:0,
        uniqueId : Math.random().toString().substring(2, 8) ,
        billID :location.state.billID
    };
    const [stockTransfer, setStockTransfer] = useState(initialValue);
 

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (itemsList.length == 0) {
                console.log("itemlis loaded !");
                await api.get('/api/stock-transfer/get-item-list')
                    .then((response) => {
                        setItemsList(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }

            if (warehouses.length == 0) {
                console.log("warehouses loaded !");
                await api.get('/api/stock-transfer/get-warehouse-list')
                    .then((response) => { 
                        setWarehouses(response.data);
                    })
                    .catch(error => {
                        NotificationManager.error(JSON.stringify(error));
                    }).finally(() => {
                    });
            }  

            await loadBillDetails();

            setLoading(false);
        })();
    }, []);

    const loadBillDetails = async () => {
        if (stockTransfer.billID > 0 && loaded == false) {
            setLoaded(true);
            await api.get('api/stock-transfer/get-stock-transfer-by-id?id=' + stockTransfer.billID)
                .then((response) => {
                    console.log("Data found !");
                    console.log(response.data);
                    var res = response.data;
                    var arr = { ...stockTransfer };
 
                    res.items.result.map(inv => {
                        let temp = { ...item }; 
                        temp.productChildId = inv.productChildId;
                        temp.qty = inv.quantity; 
                        temp.itemCode = inv.itemCode;
                        temp.itemName = inv.itemName;  
                        temp.mrp = inv.mrp; 
                        arr.items.push(temp);
                    }); 

                    arr.id = res.doc.id;
                    arr.godownFromID = res.doc.godownFromId;
                    arr.godownToID = res.doc.godownToId;
                    arr.remarks = res.doc.remarks; 
                    arr.netValue = res.doc.saleAmt;
                    arr.totalQty = res.doc.totalQty; 
                    setStockTransfer(arr);
                })
                .catch(error => {
                    NotificationManager.error('Error in loading bill details :' + JSON.stringify(error.responseText));
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoaded(true);
                    setLoading(false);
                });
        }
    }
 
    const saveStockTransfer = async () => {
        if (stockTransfer.godownFromID == 0) {
            NotificationManager.error("Select godown-from to continue..");
            warehouseFromRef.current.focus();
            return;
        }
        if (stockTransfer.godownToID == 0) {
            NotificationManager.error("Select godown-to to continue..");
            warehouseToRef.current.focus();
            return;
        } 
        if (stockTransfer.items.length == 0) {
            NotificationManager.error("Select item to continue ..");
            itemCodeRef.current.focus();
            return;
        }
        setLoading(true);
        console.log(JSON.stringify(stockTransfer));
        await api.post('api/stock-transfer/post-stock-transfer', JSON.stringify(stockTransfer))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Document Saved Successfully.");
                    setStockTransfer(initialValue);
                }
                else {
                    NotificationManager.error(JSON.stringify(response.data));
                }
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    };

    const addNewItem = () => {
        if (item.productChildId.length == 0) {
            NotificationManager.error("select item to continue.");
            itemCodeRef.current.focus();
            return;
        }
        if (item.qty == 0) {
            NotificationManager.error("Enter qty to continue.");
            qtyRef.current.focus();
            return;
        }  
        setStockTransfer({...stockTransfer,items : stockTransfer.items.push(item)});
        setItem(itemInitialValue)
        calculateAmount();
    }

    const calculateAmount = () => {
        var arr = { ...stockTransfer };
        let totalQty = 0;
        let grossValue = 0;
        arr.items.map((item) => {
            totalQty += Number(item['qty']);
            grossValue +=(Number(item['qty'])*Number(item['mrp']))
        });
        arr.netValue = Math.round(grossValue);
        arr.totalQty = totalQty;
        setStockTransfer(arr);

        itemCodeRef.current.focus();
    }

    const deleteItem = async (index) => {
        console.log(index);
        if (index >= 0) {
            var arrs = { ...stockTransfer };
            arrs.items.splice(index, 1);
            setStockTransfer(arrs);
            calculateAmount();
            return;
        }

        // setLoading(true);
        // await api.post('api/purchase/delete-inventory-by-id?id=' + id)
        //     .then((response) => {
        //         if (response.status === 200) {
        //             var arrs = { ...stockTransfer };
        //             arrs.itemList.splice(index, 1);
        //             setStockTransfer(arrs);
        //             calculateAmount();
        //         }
        //         else {
        //             NotificationManager.error(JSON.stringify(response.data));
        //         }
        //     })
        //     .catch(error => {
        //         NotificationManager.error(JSON.stringify(error.response.data));
        //     }).finally(() => {
        //         setLoading(false);
        //     });
    } 

    return (
        <div className="card-wrapper">
            <PageTitleBar title={'Stock Transfer Entry'} match={props.match} />
            <Helmet>
                <title>Purchase Entry</title>
                <meta name="description" content="Stock Transfer Entry" />
            </Helmet>
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <Button
                            variant="contained"
                            className="text-white btn-danger"
                            disabled={loading}
                            onClick={saveStockTransfer}>
                            {loading ? 'Please Wait ... ' : 'Save & Print'}<small>{'\u00A0'}{'\u00A0'}({'\u20B9'}{stockTransfer.netValue})[Qty(s) : {stockTransfer.totalQty}]</small></Button>
                    </div>
                </div>

                {loading &&
                    <RctSectionLoader />
                }
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-4">
                        <FormControl className="form-control">
                            <InputLabel>Warehouse (From)</InputLabel>
                            <Select
                                inputRef={warehouseFromRef}
                                value={stockTransfer.godownFromID}
                                onChange={(e) => setStockTransfer({...stockTransfer,godownFromID : e.target.value})}
                                style={{ width: '100%' }}
                            >
                                <MenuItem selected key={0} value={0}>----Select Warehouse (from)----</MenuItem>
                                {warehouses && warehouses.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <FormControl className="form-control">
                            <InputLabel>Warehouse (To)</InputLabel>
                            <Select
                                inputRef={warehouseToRef}
                                value={stockTransfer.godownToID}
                                onChange={(e) => setStockTransfer({...stockTransfer,godownToID : e.target.value})}
                                style={{ width: '100%' }}
                            >
                                <MenuItem selected key={0} value={0}>----Select Warehouse (from)----</MenuItem>
                                {warehouses && warehouses.map((warehouse) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.wname}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div> 
                </div> 
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-4">
                        <Autocomplete
                            value={item}
                            options={itemsList}
                            sx={{ width: '100%' }} 
                            getOptionLabel={(option) => option.productChildId.length > 0 ? option.itemCode + '-' + option.itemName + '-' + option.mrp + '-' + option.productChildId : ''}
                            onChange={(event, newValue) => {
                                setItem({
                                    productChildId : newValue.productChildId,
                                    qty:'',
                                    mrp:newValue.mrp,
                                    itemCode:newValue.itemCode,
                                    itemName:newValue.itemName
                                });
                                qtyRef.current.focus();
                            }} 
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.productChildId}>
                                    {option.itemCode + " - " + option.itemName + '-' + option.mrp + '-' + option.productChildId}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputRef={itemCodeRef}
                                    label="Choose an item"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                    <div className="col-md-2">
                        <TextField
                            inputRef={qtyRef}
                            label="Qty"
                            placeholder="Qty" type="number"
                            onChange={(e) => setItem({...item,qty:e.target.value})}
                            value={item.qty}
                            variant="outlined"
                        ></TextField>
                    </div> 
                    <div className="col-md-2">
                        <Button variant="contained" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                    </div>
                </div>
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <div className="table-responsive">
                    <Table>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Item</TableCell>  
                                <TableCell>Qty</TableCell>  
                                <TableCell>MRP</TableCell>  
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Fragment>
                                {stockTransfer && stockTransfer.items.map((item, key) => (
                                    <TableRow hover key={key}>
                                        <TableCell>{item["itemCode"]} <br /> {item["itemName"]} (<small>MRP : {'\u20B9'}{item["mrp"]}</small>)</TableCell>                                  
                                        <TableCell>{item["qty"]}</TableCell>                                  
                                        <TableCell>{item["mrp"]}</TableCell>                                  
                                        <TableCell>
                                            <>
                                                <IconButton color="primary" onClick={() => deleteItem(key)}>
                                                    <DeleteIcon />
                                                </IconButton> 
                                            </>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Fragment>
                        </TableBody>
                    </Table>
                </div>
            </RctCollapsibleCard>
        </div>
    );
}

export default StockTransfer;