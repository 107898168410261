import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input, Form } from 'reactstrap';
import { Alert } from 'reactstrap';
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';

// page title bar
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';
import api from 'api';

import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { TextField } from '@mui/material';
// rct collapsible card
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import { NotificationManager } from 'react-notifications';

var moment = require('moment');
const ItemReport = () => {
    const [orders, setOrders] = useState(null);

    const [startDate, setStartDate] = useState(new Date().toLocaleString());
    const [endDate, setEndDate] = useState(new Date().toLocaleString());
    const [searchText, setSearchText] = useState('');

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            loadData(page);
        })();
    }, [page, rowsPerPage]);

    const loadData = async (page) => {
        setLoading(true);
        console.log(page);
        await api.get('api/rest/report/get-item-report?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
            params: {
                startDate: startDate,
                endDate: endDate,
                searchText: searchText
            }
            })
            .then((response) => { 
                setCount(response.data.totalRecords);
                setOrders(response.data.data);
            })
            .catch(error => {
                console.log(JSON.stringify(error.response));
            }).finally(() => {
                setLoading(false);
            });
    }

    const exportReport = async (page) => {
        setLoading(true);
        await api.get('api/rest/report/get-item-report', {
            params: {
                startDate: startDate,
                endDate: endDate,
                searchText: searchText,
                export: true
            }
        })
            .then((msg) => {
                var a = document.createElement("a");
                a.href = msg.data;
                a.download = "ItemReport.xlsx";
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const printReport = async (page) => {
        setLoading(true);
        await api.get('api/rest/report/get-item-report', {
            params: {
                startDate: startDate,
                endDate: endDate,
                searchText: searchText,
                print: true
            }
        })
            .then((msg) => {
                // var a = document.createElement("a");
                // a.href = msg.data;
                // a.download = "ItemReport.xlsx";
                // document.body.appendChild(a);
                // a.click(); 
                console.log(msg);
                window.open(msg.data, '_blank');

                // let objFra = document.createElement('iframe');     // Create an IFrame.
                // objFra.style.visibility = 'hidden';                // Hide the frame.
                // objFra.src = msg.data;                   // Set source.
                // document.body.appendChild(objFra);  // Add the frame to the web page.
                // objFra.contentWindow.focus();       // Set focus.
                // objFra.contentWindow.print();
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error));
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleChangePage = async (newPage) => {
        //setPage(page + 1); 
        console.log("page........");
        console.log("New Page : " + newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        console.log("Rows per page changed !");
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {

        const handleFirstPageButtonClick = () => {
            setPage(0);
            loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <div className="report-wrapper">
            <Helmet>
                <title>Item Report</title>
                <meta name="description" content="Item Reports" />
            </Helmet>
            <PageTitleBar title={'Item Report'} />
            <RctCollapsibleCard>
                <div className="table-responsive">
                    {loading &&
                        <RctSectionLoader />
                    }

            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-2 mt-10">
                            <TextField
                                    type="date"
                                    label="Start Date"
                                    value={moment(startDate).format("YYYY-MM-DD")}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    /> 
                    </div>
                    <div className="col-md-2 mt-10">
                            <TextField
                                    type="date"
                                    label="End Date"
                                    value={moment(endDate).format("YYYY-MM-DD")}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    /> 
                    </div>
                    <div className="col-md-3 mt-10">
                        <TextField
                        style={{width : '100%'}}
                                    type="text"
                                    label="Search Text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    /> 
                    </div>
                    <div className='col-md-1 mt-10'> 
                        <Button color="primary" variant="contained" className="mr-10 text-white" onClick={() => loadData(0)}>Search</Button>    
                    </div>
                    <div className='col-md-1 mt-10'> 
                        <Button color='success' variant="contained" className="mr-10 text-white" onClick={exportReport}>Export</Button>                  
                    </div>  
                    <div className='col-md-1 mt-10'> 
                        <Button color='secondary' variant="contained" className="mr-10 text-white" onClick={printReport}>Print</Button>                                              
                    </div>  
                </div> 
            </RctCollapsibleCard>

                    {orders && <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><b>Item Category</b></TableCell>
                                <TableCell><b>Item Name</b></TableCell>
                                <TableCell align='right'><b>Quantity</b></TableCell>
                                <TableCell align='right'><b>Rate</b></TableCell>
                                <TableCell align='right'><b>Amount</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders && orders.map((order, key) => (
                                (order.itemCategory != null ?
                                    <TableRow key={key}>
                                        <TableCell>#</TableCell>
                                        <TableCell>{order.itemCategory}</TableCell>
                                        <TableCell>
                                            <div className="media">
                                                <div className="media-left mr-15">
                                                    {order.itemName}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='right'>{order.qty}</TableCell>
                                        <TableCell align='right'>{order.rate}</TableCell>
                                        <TableCell align='right'>{order.amount}</TableCell>
                                    </TableRow> : null)
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>#</TableCell>
                                <TableCell>#</TableCell>
                                <TableCell>#</TableCell>
                                <TableCell align='right'><b>Total</b></TableCell>
                                <TableCell align='right'>{orders[orders.length - 1].amount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePagination
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page + 1}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary"
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number"
                                        }
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    ActionsComponent={TablePaginationActions}
                                //component={Box}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>}
                </div>

            </RctCollapsibleCard>
        </div >
    )
}
export default ItemReport;