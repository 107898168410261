import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import api from 'api';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';

export default function AfterBillBox(props) {
    const [loading, setLoading] = useState(false);


    const printBill = async () => {
        setLoading(true);
        await api.get('api/rest/kot-bill/print-bill?billID=' + props.savedBillDetails.billID)
            .then((response) => {
                if (response.status === 200) {
                    window.open('' + response.data + '', 'sharer', 'toolbar=0,status=0,width=548,height=525');
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }

    const sendSMS = async () => {
        setLoading(true);
        await api.get('api/rest/kot-bill/send-sms-on-bill?billID=' + props.savedBillDetails.billID)
            .then((response) => {
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }

    const sendSMSToReferral = async () => {
        setLoading(true);
        await api.get('api/rest/kot-bill/send-sms-on-bill-referral-code?billID=' + props.savedBillDetails.billID)
            .then((response) => {
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }

    const sendSMSToReferralUsed = async () => {
        if (props.savedBillDetails.referralCode != null) {
            setLoading(true);
            await api.get('api/rest/kot-bill/send-sms-on-bill-to-referral-code-used?billID=' + props.savedBillDetails.billID)
                .then((response) => {
                })
                .catch(error => {
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <div class="row">
            {loading &&
                <RctSectionLoader />
            }
            <Button onClick={printBill}>Print Bill</Button>
            <Button onClick={() => { sendSMS(); sendSMSToReferral(); sendSMSToReferralUsed(); }
            }>Send SMS</Button>
        </div>

    )
}