import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    FormGroup,
} from 'reactstrap';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

import api from 'api';
import { NotificationManager } from 'react-notifications';
import { TextField } from '@mui/material';

export default function ReferralSettings(props) {
    const [settings, setSettings] = useState({
        discPerOnService: 0,
        discPerOnProduct: 0,
        minBillValue: 0,
        rewardPoint: 0
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSettings()
    }, []);

    const getSettings = async () => {
        setLoading(true);
        await api.get('api/settings/get-referral-settings')
            .then((response) => {
                console.log(response.data);
                setSettings(response.data);
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }

    const saveSettings = async () => {
        setLoading(true);
        await api.post('api/settings/post-referral-settings', JSON.stringify(settings))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("SUCCESS");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className='row'>
            <div className="col-md-12">
                <PageTitleBar title={'Referral Settings'} />
                {loading &&
                    <RctSectionLoader />
                }
                <RctCollapsibleCard>
                    <div className='row'>
                        <div className='col-md-3'>
                            <FormGroup className="mr-10 mb-10">
                                <TextField label="Discount (%) on Service" fullWidth value={settings.discPerOnService}
                                    onChange={(e) => setSettings({ ...settings, discPerOnService: e.target.value })} />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup className="mr-10 mb-10">
                                <TextField label="Discount (%) on Product" fullWidth value={settings.discPerOnProduct}
                                    onChange={(e) => setSettings({ ...settings, discPerOnProduct: e.target.value })} />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup className="mr-10 mb-10">
                                <TextField label="Min Bill Value" fullWidth value={settings.minBillValue}
                                    onChange={(e) => setSettings({ ...settings, minBillValue: e.target.value })} />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup className="mr-10 mb-10">
                                <TextField label="Reward Point" fullWidth value={settings.rewardPoint}
                                    onChange={(e) => setSettings({ ...settings, rewardPoint: e.target.value })} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Button className="mt-10 mb-10" color="primary" onClick={saveSettings} disabled={loading}>{loading ? 'Please wait...' : 'Save'}</Button>
                    </div>
                </RctCollapsibleCard>
            </div>
        </div>
    );
}
