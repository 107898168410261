import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import api from 'api';
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';

export default function ApplyCoupon(props) {
    const [loading, setLoading] = useState(false);

    const applyCoupon = async () => {
        if (props.billDetails.couponCode == null || props.billDetails.couponCode.length == 0 || props.billDetails.couponCode == undefined) {
            props.setBillDetails({ ...props.billDetails, couponCode: "" });
            return;
        }
        setLoading(true);
        await api.get('api/sales/get-coupon-details?couponCode=' + props.billDetails.couponCode)
            .then((response) => {
                console.log(response.data);
                var data = response.data;
                if (data != null || data != undefined) {
                    if (data.discountType == "%")
                        props.setBillDetails({ ...props.billDetails, discAmt: 0, discPer: data.discount });
                    else
                        props.setBillDetails({ ...props.billDetails, discPer: 0, discAmt: data.discount });
                }
            })
            .catch(error => {
                props.setBillDetails({ ...props.billDetails, couponCode: "" });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div class="row">
            <TextField
                variant='standard'
                inputProps={{ style: { textAlign: 'center' } }}
                label="Coupon Code (Optional)"
                value={props.billDetails.couponCode}
                onBlur={applyCoupon}
                onChange={(e) => props.setBillDetails({ ...props.billDetails, couponCode: e.target.value })}>
            </TextField>
            {loading &&
                <RctSectionLoader />
            }
        </div>

    )
}