import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'Auth';

import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';

// defaultTheme
import themes from 'themes';
import MainLayout from 'layout/MainLayout';
import Dashboard from 'views/dashboard/Default';
import Table from 'views/table/Table';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Login from 'views/login/Login';
import NotFound from 'views/login/404'
import ChangePassword from 'views/login/ChangePassword';
import ChangeBranch from 'views/login/ChangeBranch';

import TakeAway from 'views/transaction/TakeAway';
import TakeAwayList from 'views/transaction/TakeAwayList';

import CancelBill from 'views/transaction/CancelBill';
import PurchaseEntry from 'views/transaction/PurchaseEntry';
import PurchaseEntryList from 'views/transaction/PurchaseEntryList';
import StockTransferList from 'views/transaction/StockTransferList';
import StockTransfer from 'views/transaction/StockTransfer';

import BillTaxReport from 'views/reports/BilltaxReport';
import StockReport from 'views/reports/StockReport';
import ItemReport from 'views/reports/ItemReport';
import StockTransferReport from 'views/reports/StockTransferReport';
import BrandCategoryRegister from 'views/reports/BrandCategoryRegister';

import ItemMaster from 'views/master/itemMaster';
import BrandMaster from 'views/master/brandMaster';
import CategoryMasterList from 'views/master/categoryMaster';
import CategoryMaster from 'views/master/categoryMaster/AddNewItemForm';
import PriceManagementMaster from 'views/master/itemMaster/PriceManagement';
import AccountMaster from 'views/master/accountMaster/AddNewItemForm';
import ServiceProviderList from 'views/master/serviceProviderMaster';
import ServiceProviderMaster from 'views/master/serviceProviderMaster/AddNewItemForm';
import AccountMasterList from 'views/master/accountMaster';
import CouponMaster from 'views/master/couponMaster/AddNewItemForm';
import CouponMasterList from 'views/master/couponMaster';
import MopMaster from 'views/master/mopMaster/AddNewItemForm';
import MopMasterList from 'views/master/mopMaster';
import Settings from 'views/master/Settings';

import Protected from 'ProtectedRoute';
import { NotificationContainer } from 'react-notifications';
// ==============================|| APP ||============================== //


const queryClient = new QueryClient();
const App = () => {
    const customization = useSelector((state) => state.customization);

console.log(localStorage.getItem('access_token'));

    // useEffect(() => {
    //     if (isLoggedIn == null) {
    //         if (localStorage.getItem('access_token') == null) {
    //             setIsLoggedIn(false)
    //         } else {
    //             setIsLoggedIn(true)
    //         }
    //     }
    // },[isLoggedIn]);

    return (
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <AuthProvider>
                            <NotificationContainer />
                            <Routes>
                             <Route path='/login' element={localStorage.getItem('access_token') == null ? <Login /> : <Navigate to="/" replace />} /> 
                                <Route path='/' element={<Protected isLoggedIn={true}><MainLayout /></Protected>}>
                                    <Route index element={<Dashboard />} />
                                    <Route path='app/table' element={<Table />} />

                                    <Route path='app/change-password' element={<ChangePassword />} />
                                    <Route path='app/change-branch' element={<ChangeBranch />} />

                                    <Route path='app/cancel-bill' element={<CancelBill />} />

                                    <Route path='app/take-away' element={<TakeAway />} />
                                    <Route path='app/take-away-list' element={<TakeAwayList />} />

                                    <Route path='app/purchase-entry' element={<PurchaseEntry />} />
                                    <Route path='app/purchase-entry-list' element={<PurchaseEntryList />} />
                                    <Route path='app/stock-transfer-list' element={<StockTransferList />} />
                                    <Route path='app/stock-transfer' element={<StockTransfer />} />

                                    <Route path='app/category-master-list' element={<CategoryMasterList />} />
                                    <Route path='app/category-master' element={<CategoryMaster />} />

                                    <Route path='app/item-master' element={<ItemMaster />} />
                                    <Route path='app/brand-master' element={<BrandMaster />} />

                                    <Route path='app/account-master-list' element={<AccountMasterList />} />
                                    <Route path='app/account-master' element={<AccountMaster />} />

                                    <Route path='app/service-provider-master-list' element={<ServiceProviderList />} />
                                    <Route path='app/service-provider-master' element={<ServiceProviderMaster />} />

                                    <Route path='app/coupon-master-list' element={<CouponMasterList />} />
                                    <Route path='app/coupon-master' element={<CouponMaster />} />

                                    <Route path='app/mop-master-list' element={<MopMasterList />} />
                                    <Route path='app/mop-master' element={<MopMaster />} />

                                    <Route path='app/price-management' element={<PriceManagementMaster />} />
                                    <Route path='app/settings' element={<Settings />} />

                                    <Route path='app/bill-tax-report' element={<BillTaxReport />} />
                                    <Route path='app/item-report' element={<ItemReport />} />
                                    <Route path='app/stock-report' element={<StockReport />} />
                                    <Route path='app/stock-transfer-report' element={<StockTransferReport />} />
                                    <Route path='app/brand-category-report' element={<BrandCategoryRegister />} />

                                </Route>
                                <Route path='*' element={<NotFound />} />
                            </Routes>
                        </AuthProvider>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
    );
};

export default App;

