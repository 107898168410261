import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle, useRef, useLayoutEffect } from 'react';
import { NotificationManager } from 'react-notifications';

import { useFetchItemMaster } from '../../hooks/useFetchData'

import RctCollapsibleCard from 'component/RctCollapsibleCard/RctCollapsibleCard';
import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Autocomplete, TextField } from '@mui/material';
import RctSectionLoader from 'component/RctSectionLoader/RctSectionLoader';
import Box from "@mui/material/Box";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ServiceProvider from './ServiceProvider';
import api from 'api';

const ItemSelection = forwardRef((props, ref) => {
    const [selectedItem, setSelectedItem] = useState({ item: null, qty: 1 });

    const qtyRef = useRef();

    useImperativeHandle(
        ref,
        () => ({
            applyDiscount(arrs, discPer, discAmt) {
                calculateDiscount(arrs, discPer, discAmt);
            }
        }),
    )

    const { data: itemDatas, isLoading: loading } = useFetchItemMaster()

    const calculateDiscount = (farrs) => {
        console.log("calc disc called ");
        let arrs = farrs;
        let netAmt = 0;

        arrs.map((arr, key) => {
            if (arr["incTax"] === 0) {
                arr["rate"] = arr["mrp"];
            } else {
                arr["rate"] = ((arr["mrp"] * 100) / (100 + (arr["cgstper"] + arr["sgstper"]))).toFixed(2);
            }

            if (arr["discPer"] > 0) {
                arr["rate"] = parseFloat(arr["rate"] - (arr["rate"] * arr["discPer"] / 100)).toFixed(2);
            }
            arr["rate"] = arr["rate"];
            arr["taxable"] = ((arr["rate"] * arr["qty"]) - arr["specialDiscAmt"]).toFixed(2);

            arr["cgstamt"] = (arr["taxable"] * arr["cgstper"] / 100).toFixed(2);
            arr["sgstamt"] = (arr["taxable"] * arr["sgstper"] / 100).toFixed(2);
            arr["cessamt"] = (arr["taxable"] * ((arr["cessper"]) ?? 0) / 100).toFixed(2);
            arr["amount"] = (Number(arr["taxable"]) + Number(arr["cgstamt"]) + Number(arr["sgstamt"]) + Number(arr["igstamt"]) + Number(arr["cessamt"])).toFixed(2);
            netAmt += Math.round(parseFloat(arr["amount"]));
        });
        props.setBillDetails({ ...props.billDetails, netAmt: netAmt, itemList: arrs });
    };

    const onItemClick = (itemCode, itemName, mrp, productChildId, cgstPer, sgstPer, cessPer, incTax, qty) => {
        let arrs = [...props.billDetails.itemList];
        const elementsIndex = arrs.findIndex(element => element["productChildId"] == productChildId);
        if (elementsIndex === -1) {
            arrs = arrs.concat([{
                "qty": parseFloat(qty), "itemCode": itemCode, "itemName": itemName, "mrp": mrp, "discPer": 0,
                "specialDiscAmt": 0, "rate": mrp, "taxable": mrp, "cgstper": cgstPer, "cgstamt": 0, "sgstper": sgstPer,
                "sgstamt": 0, "igstper": 0, "igstamt": 0, "cessper": cessPer, "cessamt": 0, "productChildId": productChildId,
                "remarks": '', "amount": mrp, "incTax": incTax, "salesManId": 0
            }]);
            props.setBillDetails({ ...props.billDetails, itemList: arrs });
        } else {
            let currentQtyCount = arrs[elementsIndex]["qty"];
            arrs[elementsIndex]["qty"] = currentQtyCount + parseFloat(qty);
            props.setBillDetails({ ...props.billDetails, itemList: arrs });
        }
        calculateDiscount(arrs, props.billDetails.discPer, props.billDetails.discAmt)

        setSelectedItem({ item: null, qty: 1 });
    };

    const callBackIncrement = (productChildId, action) => {
        let arrs = [...props.billDetails.itemList];
        const elementsIndex = arrs.findIndex(element => element["productChildId"] == productChildId);
        let currentQtyCount = arrs[elementsIndex]["qty"];
        if (action === "+") {
            arrs[elementsIndex]["qty"] = currentQtyCount + 1;
        }
        else if (action === "-") {
            if (currentQtyCount === 1) {
                arrs.splice(elementsIndex, 1);
            } else {
                arrs[elementsIndex]["qty"] = currentQtyCount - 1;
            }
        }
        else {
            if (arrs[elementsIndex]["id"] != undefined) {
                deleteInventoryById(arrs[elementsIndex]["id"]);
                arrs.splice(elementsIndex, 1);
            }
        }
        props.setBillDetails({ ...props.billDetails, itemList: arrs });
        calculateDiscount(arrs, props.billDetails.discPer, props.billDetails.discAmt);
    };

    const onAddItemButtonClicked = () => {
        if (selectedItem.item == null) {
            NotificationManager.error("Select item to add !");
            props.itemInputRef.current.focus();
            return;
        }
        if (selectedItem.qty == 0) {
            NotificationManager.error("Enter qty !");
            qtyRef.current.focus();
            return;
        }
        let obj = selectedItem.item;
        onItemClick(obj.itemCode, obj.itemName, obj.mrp, obj.productChildId, obj.cgstPer, obj.sgstPer,
            obj.cessPer, obj.incTax, selectedItem.qty)
        props.itemInputRef.current.focus();
    };

    const onChangeRowDiscount = (productChildId, discPer) => {
        if (discPer < 0 || discPer > 100)
            discPer = 0;
        let arrs = [...props.billDetails.itemList];
        const elementsIndex = arrs.findIndex(element => element["productChildId"] == productChildId);
        if (elementsIndex != -1) {
            arrs[elementsIndex]["discPer"] = discPer;
        }
        props.setBillDetails({ ...props.billDetails, itemList: arrs });
    }

    const deleteInventoryById = async (id) => {
        if (id == 0)
            return;

        await api.post('api/sales/delete-inventory-by-id?id=' + id, null)
            .then((response) => {
            })
            .catch(error => {
            }).finally(() => {
            });
    }


    return (
        <>
            {loading &&
                <RctSectionLoader />
            }
            <div className='row'>
                <div className='col-md-12'>
                    <div className='row mb-10'>
                        <div className='col-md-8'>
                            {itemDatas && <Autocomplete
                                value={selectedItem.item}
                                options={itemDatas.data}
                                sx={{ width: '100%' }}
                                getOptionLabel={(option) => option.itemName + " - " + option.itemCode + " - " + option.mrp}
                                onChange={(e, v) => {
                                    setSelectedItem({ ...selectedItem, item: v });
                                    qtyRef.current.focus();
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.itemName + " - " + option.itemCode + " - " + option.mrp}
                                    </Box>
                                )}
                                renderInput={
                                    (params) => <TextField {...params} label="Autocomplete (ItemName)"
                                        inputRef={props.itemInputRef} />
                                }
                            />}
                        </div>
                        <div className='col-md-3'>
                            <TextField type='number' fullWidth label="Qty" value={selectedItem.qty}
                                inputRef={qtyRef}
                                onChange={(e) => setSelectedItem({ ...selectedItem, qty: e.target.value.length == 0 ? 0 : e.target.value })}
                                onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                        onAddItemButtonClicked()
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <RctCollapsibleCard>
                        <div className="table-responsive">
                            <Table>
                                <TableHead>
                                    <TableRow hover>
                                        <TableCell>Item</TableCell>
                                        <TableCell>Service Provider</TableCell>
                                        <TableCell>Disc (%)</TableCell>
                                        <TableCell>Disc (Amt)</TableCell>
                                        <TableCell>Taxable</TableCell>
                                        <TableCell>GST</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Fragment>
                                        {props.billDetails.itemList && props.billDetails.itemList.map((menuItem, key) => (
                                            <TableRow hover key={key}>
                                                <TableCell>{menuItem["itemName"]} <br /> (<small>MRP : {'\u20B9'}{menuItem["mrp"]}</small>)</TableCell>
                                                <TableCell><ServiceProvider salesManId={menuItem["salesManId"]} productChildId={menuItem["productChildId"]} billDetails={props.billDetails} setBillDetails={props.setBillDetails} /></TableCell>
                                                <TableCell><TextField type='number' label='Disc (%)' value={menuItem["discPer"]} onChange={(e) => onChangeRowDiscount(menuItem["productChildId"], e.target.value)} /></TableCell>
                                                <TableCell>{menuItem["specialDiscAmt"]}</TableCell>
                                                <TableCell>{menuItem["taxable"]}<br /> <small><b>({menuItem["qty"]} * {menuItem["rate"]})</b></small></TableCell>
                                                <TableCell>{menuItem["cgstamt"]} + {menuItem["sgstamt"]} </TableCell>
                                                <TableCell>{menuItem["amount"]} </TableCell>
                                                <TableCell> <div>
                                                    <IconButton color="primary" onClick={() => callBackIncrement(menuItem["productChildId"], "+")}>
                                                        <AddCircleOutlineRoundedIcon></AddCircleOutlineRoundedIcon>
                                                    </IconButton>
                                                    <IconButton color="primary" onClick={() => callBackIncrement(menuItem["productChildId"], "-")}>
                                                        <RemoveCircleOutlineRoundedIcon></RemoveCircleOutlineRoundedIcon>
                                                    </IconButton>
                                                    <IconButton color="error" onClick={() => callBackIncrement(menuItem["productChildId"], "*")}>
                                                        <DeleteOutlineRoundedIcon></DeleteOutlineRoundedIcon>
                                                    </IconButton>
                                                </div></TableCell>
                                            </TableRow>
                                        ))}
                                    </Fragment>
                                </TableBody>
                            </Table>
                        </div>
                    </RctCollapsibleCard>
                </div>
            </div>
        </>
    )
})
export default ItemSelection;



