/**
 * SAAS Dashboard
 */
import React, { useState, useEffect } from 'react'



// page title bar
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';
import api from '../../../api';
import { Helmet } from "react-helmet";
import {
    TotalSalesWidget,
    NetProfitWidget
} from "../../../component/Widgets";
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Refresh';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';

const Dashbaord = (props) => {
    const [totalDailySales, setTotalDailySales] = useState(0);
    const [billCount, setBillCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalSales, setTotalSales] = useState({
        label: 'Sales',
        chartdata: [250, 310, 150, 420, 250, 450, 900],
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    });
    const [netProfit, setNetProfit] = useState({
        label: 'Bill Count',
        chartdata: [250, 310, 150, 420, 250, 450, 500],
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    });

    useEffect(() => {
        LoadReport();
    }, []);

    console.log("loading dashboard....");

    const LoadReport = async () => {
        setLoading(true);
        (async () => {
            await api.get('api/rest/report/get-sale-summary?valueFor=0')
                .then((response) => {
                    console.log(response.data);
                    if (response.data.length > 0) {
                        setTotalDailySales(response.data[0].total);
                        setBillCount(response.data[0].count);
                    } else {
                        setTotalDailySales(0);
                        setBillCount(0);
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                });
        })();
        setLoading(false);
    }


    return (
        <div className="saas-dashboard">
            <Helmet>
                <title>Dashboard</title>
                <meta name="description" content="BillTax Reports" />
            </Helmet>
            <PageTitleBar title='Dashboard' />
            <div className="row">
                <IconButton color="primary" onClick={LoadReport}>
                    <DeleteIcon />
                </IconButton>
            </div>
            {loading &&
                <RctSectionLoader />
            }
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-12 w-xs-full">
                    <div className="row">
                        <div className="col-sm-3 col-md-3 col-lg-3">
                            <TotalSalesWidget
                                label={totalSales.label}
                                chartdata={totalSales.chartdata}
                                labels={totalSales.labels}
                                totalsales={totalDailySales}
                            />
                        </div>
                        <div className="col-sm-3 col-md-3 col-lg-3">
                            <NetProfitWidget
                                label={netProfit.label}
                                chartdata={netProfit.chartdata}
                                labels={netProfit.labels}
                                billcount={billCount}
                            />
                        </div>
                        {/* <div className="col-sm-3 col-md-3 col-lg-3">
                         <TaxStatsWidget
                            label={taxStats.label}
                            chartdata={taxStats.chartdata}
                            labels={taxStats.labels}
                         />
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3">
                         <ExpensesWidget
                            label={expenses.label}
                            chartdata={expenses.chartdata}
                            labels={expenses.labels}
                         />
                      </div> */ }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashbaord;