import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard';

import api from 'api';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import { UncontrolledAlert } from 'reactstrap';

const DayClosing = () => {
    const [currentDate, setCurrentDate] = useState();
    const [startSubmit, setStartSubmit] = useState(false);

    console.log(currentDate);

    useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        await api.get('api/rest/day-closing/get-billing-date')
            .then((response) => {
                setCurrentDate(response.data.dateOfSale);
            })
            .catch(error => {
            });
    }

    const saveDate = async () => {
        setStartSubmit(true);
        const billData = {
            dateOfSale: currentDate,
        };
        await api.post('api/rest/day-closing/set-billing-date', JSON.stringify(billData))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("SUCCESS");
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
            }).finally(() => {
                setStartSubmit(false);
            });
    }

    return (
        <div className="formelements-wrapper">
            <PageTitleBar title={'Day Closing'} />
            <RctCollapsibleCard>
                <UncontrolledAlert color="success">
                    Current Billing Date :  {moment(currentDate).format("DD-MMM-yyyy")}
                </UncontrolledAlert>
            </RctCollapsibleCard>
            <RctCollapsibleCard>
                <Form inline>
                    <FormGroup className="mr-10 mb-10">
                        <Label for="Email-7">Select Date</Label>
                        <Input type="date" name="email" id="Email-7" placeholder="dd/MM/yyyy" value={moment(currentDate).format("YYYY-MM-DD")} onChange={(e) => setCurrentDate(e.target.value)} />
                    </FormGroup>
                    <Button className="mb-10" color="primary" onClick={saveDate} disabled={startSubmit}>{startSubmit ? 'Please wait...' : 'Set Date'}</Button>
                </Form>
            </RctCollapsibleCard>

        </div>
    );
}

export default DayClosing;