import React from 'react';
import { TextField } from '@mui/material';
import RctCollapsibleCard from 'component/RctCollapsibleCard/RctCollapsibleCard';
import { NotificationManager } from 'react-notifications';

export default function ApplyDiscount(props) {

    const onChangeDiscountPer = (discPer) => {
        if (discPer < 0 || discPer > 100) {
            NotificationManager.error("Invalid discount (%) !");
            props.setBillDetails({ ...props.billDetails, discPer: 0 });
            return;
        }
        var arrs = props.billDetails.itemList;
        arrs.map((arr, key) => {
            arr["discPer"] = discPer;
        });
        props.setBillDetails({ ...props.billDetails, discPer: discPer });
    }

    const onChangeDiscountAmt = (discAmt) => {
        if (discAmt < 0)
            discAmt = 0;
        let arrs = props.billDetails.itemList; 
        let qm = 0;
        arrs.map((arr, key) => {
            if (arr["incTax"] === 0) {
                qm += (arr["mrp"] * arr["qty"]);
            } else {
                qm += (((arr["mrp"] * 100) / (100 + (arr["cgstper"] + arr["sgstper"]))) * arr["qty"]);
            }
        });

        if (discAmt > qm) {
            NotificationManager.error("Invalid discount amount , it could not be greater than " + qm + "!");
            props.setBillDetails({ ...props.billDetails, discAmt: 0, discPer: 0 });
            return;
        }

        arrs.map((arr, key) => {
            if (arr["incTax"] === 0) {
                arr["rate"] = arr["mrp"]
            } else {
                arr["rate"] = ((arr["mrp"] * 100) / (100 + (arr["cgstper"] + arr["sgstper"])))
            }

            arr["discPer"] = 0;
            arr["specialDiscAmt"] = 0;
            arr["specialDiscAmt"] = ((discAmt / qm) * (arr["rate"] * arr["qty"])).toFixed(2);

        });
        props.setBillDetails({ ...props.billDetails, itemList: arrs, discAmt: discAmt, discPer: 0 });
    };

    return (
        <RctCollapsibleCard>
            <TextField
                variant='outlined'
                inputProps={{ style: { textAlign: 'right' } }}
                type='number'
                label="Discount (%)"
                value={props.billDetails.discPer}
                onChange={(e) => onChangeDiscountPer(e.target.value)}>
            </TextField>
            <TextField
                variant='outlined'
                inputProps={{ style: { textAlign: 'right' } }}
                type='number'
                label="Discount (Amt)"
                value={props.billDetails.discAmt}
                onChange={(e) => onChangeDiscountAmt(e.target.value)}>
            </TextField>
        </RctCollapsibleCard>
    )
}