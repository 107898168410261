/**
 * Page Title Bar Component
 * Used To Display Page Title & Breadcrumbs
 */
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

// get display string
const getDisplayString = (sub) => {
   const arr = sub.split("-");
   if (arr.length > 1) {
      return arr[0].charAt(0) + arr[0].slice(1) + arr[1].charAt(0).toUpperCase() + arr[1].slice(1);
   } else {
      return sub.charAt(0) + sub.slice(1);
   }
};

// get url string
const getUrlString = (path, sub, index) => {
   if (index === 0) {
      return '/';
   } else {
      return '/' + path.split(sub)[0] + sub;
   }
};

const PageTitleBar = ({ title }) => {
   return (
      <div className="page-title d-flex justify-content-between align-items-center">
         {title &&
            <div className="page-title-wrap">
               <i className="ti-angle-left"></i>
               <h2 className="">{title}</h2>
            </div>
         }
      </div>
   )
};

// default props value
PageTitleBar.defaultProps = {
   enableBreadCrumb: true
}

export default PageTitleBar;
