// assets
import { IconChartInfographic, IconKey, IconTools, IconSortAscendingNumbers } from '@tabler/icons';

// constant
const icons = {
    IconKey, IconChartInfographic, IconTools, IconSortAscendingNumbers
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'menu',
    title: 'Menu',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'transaction',
            title: 'Transaction',
            type: 'collapse',
            icon: icons.IconTools,
            children: [
                {
                    id: 'listofbills',
                    title: 'Bill',
                    type: 'item',
                    url: '/app/take-away-list',
                    breadcrumbs: false
                },
                // { 
                //     id: 'cancelbill',
                //     title: 'Cancel Bill',
                //     type: 'item',
                //     url: '/app/cancel-bill',
                //     breadcrumbs: false
                // },
                {
                    id: 'purchaseentry',
                    title: 'Purchase Entry',
                    type: 'item',
                    url: '/app/purchase-entry-list',
                    breadcrumbs: false
                },
                {
                    id: 'stocktransfer',
                    title: 'Stock Transfer',
                    type: 'item',
                    url: '/app/stock-transfer-list',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default pages;
