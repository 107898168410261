import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { NotificationManager } from 'react-notifications';
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';
// api
import api from 'api';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
export default function AddNewItemForm() {
    const location = useLocation();
    const navigate = useNavigate()
    const [accounts, setAccounts] = useState(null);

    const id = location.state.id;

    const [addNewItemDetail, setAddNewItemDetail] = useState({
        id: 0,
        mopName: '',
        mopType: 'Cash',
        accountID: 0,
        status: 'Active'
    });

    const [loading, setLoading] = useState(false);

    const addNewItem = async () => {
        if (addNewItemDetail.mopName === '') {
            NotificationManager.error("MopName Required !");
            return;
        }
        setLoading(true);
        console.log(JSON.stringify(addNewItemDetail));
        await api.post('api/master/mop', JSON.stringify(addNewItemDetail))
            .then((response) => {
                console.log(response.data);
                NotificationManager.success("Added Successfully.");
                navigate("/app/mop-master-list");
            })
            .catch(error => {
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                setLoading(true);
                await api.get('api/master/mop/' + id)
                    .then((response) => {
                        console.log(response.data);

                        setAddNewItemDetail({
                            ...addNewItemDetail,
                            id: response.data.id,
                            mopName: response.data.mopname,
                            mopType: response.data.moptype,
                            accountID: response.data.accountId,
                            status: response.data.status
                        })
                    })
                    .catch(error => {
                    }).finally(() => {
                        setLoading(false);
                    });
            })();
        }
    }, [id > 0]);

    useEffect(() => {
        if (accounts === null) {
            (async () => {
                await api.get('api/master/account/get-account-list-for-mop')
                    .then((response) => {
                        console.log(response.data);
                        setAccounts(response.data);
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error.response.data));
                    });
            })();
        }
    })

    const updateItem = async () => {
        if (addNewItemDetail.mopName === '') {
            NotificationManager.error("MopName Required !");
            return;
        }

        setLoading(true);
        await api.put('api/master/mop?id=' + id, JSON.stringify(addNewItemDetail))
            .then((response) => {
                NotificationManager.success("Updated Successfully.");
                navigate("/app/mop-master-list");
            })
            .catch(error => {
                NotificationManager.error(JSON.stringify(error.response.data));
            }).finally(() => {
                setLoading(false);
            });
    }
    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron Salon | MOP Master</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'MOP Master'}
            />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="MOP Name"
                                value={addNewItemDetail.mopName}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mopName: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                                autoFocus={true}
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">MOP Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={addNewItemDetail.mopType}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, mopType: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                <MenuItem selected key={0} value="Cash">Cash</MenuItem>
                                <MenuItem key={1} value="Credit Card">Credit Card</MenuItem>
                                <MenuItem key={1} value="Credit Sale">Credit Sale</MenuItem>
                                <MenuItem key={1} value="CRM Point Redeem">CRM Point Redeem</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={addNewItemDetail.accountID}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, accountID: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                <MenuItem selected key={0} value={0}>---Select Account---</MenuItem>
                                {accounts && accounts.map((account) =>
                                    <MenuItem key={account.id} value={account.id}>{account.accountName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-6'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                value={addNewItemDetail.status}
                                onChange={(e) => setAddNewItemDetail({ ...addNewItemDetail, status: e.target.value })}
                                style={{ width: '100%', marginBottom: 15 }}
                                variant="standard"
                            >
                                <MenuItem selected key={0} value="Active">Active</MenuItem>
                                <MenuItem key={1} value="InActive">InActive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-12 text-right'>
                        {id == 0 ?
                            <Button variant="contained" color="secondary" className="text-white btn-success" onClick={() => addNewItem()}>Add</Button>
                            :
                            <Button variant="contained" color="primary" className="text-white" onClick={() => updateItem()}>Update</Button>}

                        {'  '}
                        <Button variant="contained" color="error" className="text-white" onClick={() => navigate("/app/mop-master-list")}>Cancel</Button>
                    </div>
                </div>

            </RctCollapsibleCard>
        </div>
    )
}
