import React, { useState, useEffect } from 'react';
import RctCollapsibleCard from 'component/RctCollapsibleCard/RctCollapsibleCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TextField, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { NotificationManager } from 'react-notifications';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import api from 'api';
import RctSectionLoader from 'component/RctSectionLoader/RctSectionLoader';

export default function MakePayment(props) {
    const [selectedMop, setSelectedMop] = useState(0);
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const addRow = () => {
        var mop = props.mops.filter(k => k.mopid == selectedMop)[0];

        if (mop == undefined || mop == null)
            return;
        let arrs = [...props.billDetails.mopList];
        console.log(arrs);
        const elementsIndex = arrs.findIndex(element => element["mopid"] == selectedMop);
        if (elementsIndex === -1) {
            arrs = arrs.concat([{
                "mopid": parseFloat(mop.mopid), "mopname": mop.mopname, "amount": amount
            }]);


            let paid = 0;
            arrs.map((arr, key) => {
                paid += parseFloat(arr["amount"]);
            });

            props.setBillDetails({ ...props.billDetails, paidAmount: paid, mopList: arrs });
        } else {
            NotificationManager.error("Already in list");
            return;
        }
        setAmount(0);
        //calculatePaid();
    }

    const deleteRow = (mopid) => {
        let arrs = [...props.billDetails.mopList];
        const elementsIndex = arrs.findIndex(element => element["mopid"] == mopid);

        arrs.splice(elementsIndex, 1);

        let paid = 0;
        arrs.map((arr, key) => {
            paid += parseFloat(arr["amount"]);
        });

        props.setBillDetails({ ...props.billDetails, paidAmount: paid, mopList: arrs });
        //  calculatePaid();
    };

    useEffect(() => {
        var mop = props.mops.filter(k => k.mopid == selectedMop)[0];
        console.log(mop);
        if (mop == undefined || mop == null) {
            return;
        }

        if (mop.moptype == "CRM Point Redeem") {
            setReadOnly(true);
            if (props.billDetails.partyID == 0) {
                NotificationManager.error("Select customer to get redeem balance !");
                return;
            }
            (async () => {
                setLoading(true);
                await api.get('api/sales/get-point-balance-in-rs?partyId=' + props.billDetails.partyID)
                    .then((response) => {
                        let point = response.data;
                        let duesAmt = props.billDetails.netAmt - props.billDetails.paidAmount;
                        console.log(response.data);
                        console.log(duesAmt);
                        if (point == 0) {
                            NotificationManager.error("Customer does not have point !");
                            setAmount(0);
                        }
                        else if (duesAmt >= point) {
                            setAmount(point);
                        }
                        else if (duesAmt < point) {
                            setAmount(point - duesAmt);
                        }
                    })
                    .catch(error => {
                    }).finally(() => {
                        setLoading(false);
                    });
            })();
        } else {
            setAmount(props.billDetails.netAmt - props.billDetails.paidAmount);
            setReadOnly(false);
        }
    }, [selectedMop]);

    return (
        <RctCollapsibleCard>
            <h3>Bill Settlement</h3><br></br>
            <div className='row'>
                <div className='col-md-6'>
                    <TextField
                        id="standard-read-only-input-2"
                        label="Payable Amount"
                        value={props.billDetails.netAmt}
                        defaultValue={props.billDetails.netAmt}
                        style={{ backgroundColor: 'firebrick' }}
                        InputProps={{
                            readOnly: true,
                            style: { textAlign: "center", backgroundColor: "#5cb85c", color: "#FFF" }
                        }}
                        variant="outlined"
                    />
                </div>
                <div className='col-md-6'>
                    <TextField
                        id="standard-read-only-input-1"
                        label="Paid Amount"
                        value={props.billDetails.paidAmount}
                        defaultValue={props.billDetails.paidAmount}
                        style={{ backgroundColor: 'firebrick' }}
                        InputProps={{
                            readOnly: true,
                            style: { textAlign: "center", backgroundColor: "#F32013", color: "#FFF" }
                        }}
                        variant="outlined"
                    />
                </div>
            </div>

            <div className='row' style={{ marginTop: 20 }}>
                <div className='col-md-6'>
                    <FormControl sx={{ width: "100%" }} size="medium">
                        <InputLabel id="demo-simple-select-label">MOP</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMop}
                            label="MOP"
                            onChange={(e) => setSelectedMop(e.target.value)}
                        >
                            {props.mops && props.mops.map((mop) =>
                                <MenuItem selected key={mop.mopid} value={mop.mopid}>{mop.mopname}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-md-4'>
                    <TextField
                        type='number'
                        inputProps={{ style: { textAlign: 'center' } }}
                        label="Amount"
                        value={amount}
                        disabled={readOnly}
                        onChange={(e) => setAmount(e.target.value)}>
                    </TextField>
                </div>
                <div className='col-md-2'>
                    <Button style={{ marginTop: 10, marginLeft: 0 }} onClick={addRow}>Add</Button>
                    {loading &&
                        <RctSectionLoader />
                    }
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className="table-responsive">
                    <Table>
                        <TableBody>
                            <>
                                {props.billDetails.mopList.length > 0
                                    && props.billDetails.mopList
                                    && props.billDetails.mopList.map((mopData, key) => (
                                        <TableRow hover key={key}>
                                            <TableCell>{mopData["mopname"]}</TableCell>
                                            <TableCell>
                                                <input type="number" value={mopData["amount"]}
                                                    className="form-control"
                                                    //onChange={(e) => calculatePaid()}
                                                    min="0"
                                                    style={{ textAlign: 'center' }}
                                                    readOnly />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton color="error" onClick={() => deleteRow(mopData["mopid"])}>
                                                    <DeleteOutlineRoundedIcon></DeleteOutlineRoundedIcon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        </TableBody>
                    </Table>
                </div>
            </div>
        </RctCollapsibleCard>
    )
}