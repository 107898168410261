import dashboard from './dashboard';
import table from './table';
import transaction from './transaction';
import reports from './reports';
import configuration from './configuration';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, transaction, configuration, reports]
};

export default menuItems;
