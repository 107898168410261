/**
 * User Management Page
 */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
// api
import api from 'api';

// delete confirmation dialog
import DeleteConfirmationDialog from '../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';

import AddIcon from '@mui/icons-material/Add';
// page title bar   
import PageTitleBar from '../../component/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from '../../component/RctCollapsibleCard/RctCollapsibleCard';
import Box from '@mui/material/Box';
// rct section loader
import RctSectionLoader from '../../component/RctSectionLoader/RctSectionLoader';
import moment from 'moment';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';

export default function CategoryMaster(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemLoadedFirstTime, setItemLoadedFirstTime] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openViewItemDialog, setOpenViewItemDialog] = useState(false);
    const deleteConfirmationDialog = useRef(null);

    const [params, setParams] = useState({
        startDate: new Date().toLocaleString(),
        endDate: new Date().toLocaleString(),
        partyId: 0,
        status: 'Complete'
    });

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    console.log(JSON.stringify(params));

    useEffect(() => {
        console.log(localStorage.getItem('user_id'));
        if (itemLoadedFirstTime === false) {
            loadItems();
        }
    }, [items]);

    useEffect(() => {
        (async () => {
            loadItems(page);
        })();
    }, [page, rowsPerPage]);

    const loadItems = async () => {
       
        (async () => {
            setLoading(true);
            await api.get('api/sales/get-sales-list?pageNumber=' + (page + 1) + '&pageSize=' + rowsPerPage,
                {
                    params: params
                }
            )
                .then((response) => {
                    console.log(response.data.data);
                    setCount(response.data.totalRecords);
                    setItemLoadedFirstTime(true);
                    setItems(response.data.data);
                })
                .catch(error => {
                    console.log(JSON.stringify(error));
                }).finally(() => {
                    setLoading(false);
                });
        })();
    }

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    }

    const deleteItemPermanently = async () => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        await api.delete('api/sales/delete-sale-entry?invoiceID=' + selectedItem.id)
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Deleted!');
                    loadItems();
                }
                else {
                    NotificationManager.error(JSON.stringify(response.message.data));
                }
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChangePage = async (newPage) => {
        //setPage(page + 1); 
        console.log("page........");
        console.log("New Page : " + newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        console.log("Rows per page changed !");
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {

        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron Salon | Bill</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'List of Bill(s)'}
                match={props.match}
            />
            {loading &&
                <RctSectionLoader />
            }
            <RctCollapsibleCard>
                <div className="row">
                    <div className="col-md-2">
                        <TextField
                            type="date"
                            label="Start Date"
                            value={moment(params.startDate).format("YYYY-MM-DD")}
                            onChange={(e) => setParams({ ...params, startDate: e.target.value })}
                        />
                    </div>
                    <div className="col-md-2">
                        <TextField
                            type="date"
                            label="End Date"
                            value={moment(params.endDate).format("YYYY-MM-DD")}
                            onChange={(e) => setParams({ ...params, endDate: e.target.value })}
                        />
                    </div>
                    <div className='col-md-1 mt-5'>
                        <Button color="primary" variant="contained" className="mr-10 text-white" onClick={() => loadItems(0)}>Search</Button>
                    </div>
                    {/* <div className='col-md-1 mt-10'>
                        <Button color='success' variant="contained" className="mr-10 text-white" onClick={exportReport}>Export</Button>
                    </div> */}
                    <div className='col-md-7 text-right mt-20'>
                        <Link to='/app/take-away' state={{ id: 0 }}>
                            Add New Invoice
                        </Link>
                    </div>
                </div>
            </RctCollapsibleCard>
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    {items && <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Invoice No.</b></TableCell>
                                <TableCell><b>Invoice Date</b></TableCell>
                                <TableCell><b>Party Name</b></TableCell>
                                <TableCell><b>Created By</b></TableCell>
                                <TableCell><b>Bill Value</b></TableCell>
                                {localStorage.getItem('user_id').toLowerCase() == "admin" && <TableCell><b>Actions</b></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items && items.length > 0 && items.map((item, key) => (
                                (item.invoiceNo != null ?
                                    <TableRow key={key}>
                                        <TableCell>{item.invoiceNo}</TableCell>
                                        <TableCell>{moment(item.invoiceDate).format("DD-MMM-YYYY")}</TableCell>
                                        <TableCell>{item.partyName}</TableCell>
                                        <TableCell>{item.createdBy} <br /> {moment(item.creationTime).format("DD-MMM-YYYY HH:mm A")}</TableCell>
                                        <TableCell>{item.billValue}</TableCell>
                                        {
                                            localStorage.getItem('user_id').toLowerCase() == "admin" && <TableCell>
                                                <Link to='/app/take-away' state={{ id: item.id }}>
                                                    <IconButton
                                                        aria-label="Edit"
                                                        size='small'
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Link>

                                                <IconButton
                                                    onClick={() => onDelete(item)}
                                                    aria-label="Delete"
                                                    size='small'
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        }
                                    </TableRow> : null)
                            )
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell align='right'>Total :  </TableCell>
                                <TableCell>{items.length > 0 && items[items.length - 1].billValue}</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                            </TableRow>
                            <TableRow>
                                <TablePagination
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    labelRowsPerPage={<span>Rows:</span>}
                                    labelDisplayedRows={({ page }) => {
                                        return `Page: ${page + 1}`;
                                    }}
                                    backIconButtonProps={{
                                        color: "secondary"
                                    }}
                                    nextIconButtonProps={{ color: "secondary" }}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "page number"
                                        }
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>}
                </div>
            </RctCollapsibleCard >


            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="Are You Sure Want To Delete?"
                message="This will delete permanently."
                onConfirm={() => deleteItemPermanently()}
            />
            <Dialog
                onClose={() => setOpenViewItemDialog(false)}
                open={openViewItemDialog}
            >
                <DialogContent>
                    {selectedItem !== null &&
                        <div>
                            <div className="clearfix d-flex">
                                <div className="media pull-left">
                                    <div className="media-body">
                                        <p>Category: <span className="fw-bold">{selectedItem.productGrp}</span></p>
                                        <p>Status   : <span className={selectedItem.status == "Active" ? "badge badge-primary" : "badge badge-danger"}>{selectedItem.status}</span></p>
                                        <p>Creation : {moment(selectedItem.creationTime).format("DD-MMM-yyyy")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        </div >
    );
}
