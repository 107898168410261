/**
 * User Management Page
 */
import React, { useState, useEffect, Fragment } from 'react';
import { Helmet } from "react-helmet";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { NotificationManager } from 'react-notifications';
import { Form, FormGroup, Label } from 'reactstrap';
// api
import api from 'api';


// page title bar
import PageTitleBar from '../../../component/PageTitleBar/PageTitleBar';

// rct card box
import RctCollapsibleCard from '../../../component/RctCollapsibleCard/RctCollapsibleCard';

// rct section loader
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl } from '@mui/material';

export default function PriceManagement(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchID, setBranchID] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (branches.length === 0) {
            (async () => {
                getBranchList();
            })();
        }
    }, []);

    const getBranchList = async () => {
        setLoading(true);
        await api.get('api/master/branch')
            .then((response) => {
                if (response.status === 200) {
                    setBranches(response.data);
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.message);
            }).finally(() => {
                setLoading(false);
            });
    };

    const loadItems = async (tbranchID) => {
        setLoading(true);
        await api.get('api/master/product/get-product-list-branch?branchID=' + tbranchID)
            .then((response) => {
                setItems(response.data);
            })
            .catch(error => {
                console.log(JSON.stringify(error.message.data));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onReload = async (e) => {
        e.preventDefault();
        await loadItems(branchID);
    }

    const handleBranchChange = async (e) => {
        setBranchID(e.target.value);
        await loadItems(e.target.value);
    };

    const updatePrice = async () => {
        setLoading(true);
        await api.post('api/master/product/update-product-list-branch', JSON.stringify(items))
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success("Items Saved Successfully.");
                    loadItems();
                }
                else {
                    NotificationManager.success(JSON.stringify(response));
                }
            })
            .catch(error => {
                NotificationManager.error(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onChangePrice = (value, id, valueFor) => {
        let arrs = [...items];
        const elementsIndex = arrs.findIndex(element => element["id"] == id);
        if (valueFor == "mrp") {
            arrs[elementsIndex]["mrp"] = value;
        } else {
            arrs[elementsIndex]["tag"] = value;
        }
        setItems(prevState => arrs);
    };

    return (
        <div className="user-management">
            <Helmet>
                <title>Billtron POS | Price Management</title>
                <meta name="description" content="Reactify Widgets" />
            </Helmet>
            <PageTitleBar
                title={'Price Management'}
                match={props.match}
            />
            <RctCollapsibleCard>
                {loading &&
                    <RctSectionLoader />
                }
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"></i></a>
                        </div>
                        <div>
                            <FormControl sx={{ width: "100%" }} size="small">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={branchID}
                                    onChange={handleBranchChange}
                                >
                                    <MenuItem value="0">---Select Branch---</MenuItem>
                                    {branches && branches.map((branch) => <MenuItem key={branch.id} value={branch.id}>{branch.branchCode}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <Button variant="contained" color="primary" className="text-white" onClick={updatePrice}>Update Price</Button>
                        </div>
                    </div>

                    <RctCollapsibleCard fullBlock>
                        <div className="col-lg-12 col-md-12 col-12">
                            <RctCollapsibleCard>
                                <div className="table-responsive">
                                    <Table>
                                        <TableHead>
                                            <TableRow hover>
                                                <TableCell>Delete</TableCell>
                                                <TableCell>ItemCode</TableCell>
                                                <TableCell>ItemName</TableCell>
                                                <TableCell>Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <Fragment>
                                                {items && items.map((item, key) => (
                                                    <TableRow hover key={key}>
                                                        <TableCell><Checkbox checked={item["tag"]} onChange={(e) => onChangePrice(!item["tag"], item.id, "tag")} /></TableCell>
                                                        <TableCell>{item["itemCode"]}</TableCell>
                                                        <TableCell>{item["itemName"]}</TableCell>
                                                        <TableCell>
                                                            <div>
                                                                <input type="text" value={item["mrp"]} onChange={(e) => onChangePrice(e.target.value, item.id, "mrp")}></input>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </Fragment>
                                        </TableBody>
                                    </Table>
                                </div>
                            </RctCollapsibleCard>
                        </div>
                    </RctCollapsibleCard>

                </div>


            </RctCollapsibleCard>




        </div>
    );

}
